import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent as ReactKeyboardEvent,
} from "react";

import s from "@/commands/AssistantOnly/Input.module.scss";

export type Props = {
  id?: string;
  disabled?: boolean;
  name?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  onKeyDown?: (e: ReactKeyboardEvent<HTMLInputElement>) => void;
  onCompositionStart?: () => void;
  onCompositionEnd?: () => void;
  isMobile?: boolean;
};

export function Input({
  id,
  disabled,
  name,
  maxLength,
  onChange,
  value,
  type,
  onKeyDown,
  onCompositionStart,
  onCompositionEnd,
  isMobile = false,
}: Props): JSX.Element {
  const placeholder = disabled ? "お話しください" : "質問を入力してください";
  return (
    <input
      className={[s.TextInput, isMobile ? s.Mobile : undefined].join(" ")}
      id={id}
      name={name}
      maxLength={maxLength}
      onChange={onChange}
      value={value}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onCompositionStart={onCompositionStart}
      onCompositionEnd={onCompositionEnd}
    />
  );
}
