import { ChangeEvent, forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ButtonMicroPhone } from "@/components/ButtonMicroPhone";
import { ButtonSend } from "@/components/ButtonSend";
import { TextArea } from "@/components/TextArea";

const InputAreaWrapper = styled("div").withConfig({
  shouldForwardProp: (prop) => !["inputAreaHeight"].includes(prop),
})<{ inputAreaHeight: string }>`
  display: flex;
  flex-direction: row;
  width: 400px;
  padding: 16px 24px;
  align-items: flex-end;
  background: #f7fafc;
  border-top: 1px solid #c4dce5;
  height: ${(props) => props.inputAreaHeight}px;

  &.mobile {
    width: 100%;
    padding: 12px 24px;
    position: fixed;
    bottom: 0;
  }

  .textInputWidth {
    flex-grow: 1;
  }

  .buttonSendMargin {
    margin: 0 0 0 12px;
  }

  .buttonMicroPhoneMargin {
    margin: 0 0 0 8px;
  }

  .textareaStyle {
    color: #16566f;
    padding: 16px 24px;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #c4dce5;
    background: #fff;
    transition: border 0.3s ease;
    cursor: pointer;
    height: -webkit-fill-available;
    flex-grow: 1;

    &.mobile {
      padding: 12px 16px;
    }

    &::placeholder {
      color: #6e9db0;
    }

    &:hover {
      border: 1px solid #21a8d2;
    }

    &:focus {
      outline: none;
      border: 1px solid #21a8d2;
    }
  }
`;

type Props = {
  isMobile: boolean;
  disabled: boolean;
  text: string;
  textOnChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleOnSend: () => void;
  startRecording: () => void;
  stopRecording: () => void;
  isMicRecording: boolean;
};

export const InputArea = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isComposing, setIsComposing] = useState(false);
  const [inputAreaHeight, setInputAreaHeight] = useState("80");
  const mobileClass = props.isMobile ? "mobile" : "";

  // textareaの高さをもとにinputAreaの高さを変更する
  // その際、textareaの高さにpadding分を足す
  // paddingの値は合計32px
  useEffect(() => {
    const currentTextAreaRef = textAreaRef.current;

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const newHeight = (entry.target.clientHeight + 32).toString();
        setInputAreaHeight(newHeight);
      });
    });

    if (currentTextAreaRef) {
      observer.observe(currentTextAreaRef);
    }

    return () => {
      if (currentTextAreaRef) {
        observer.unobserve(currentTextAreaRef);
      }
    };
  }, []);

  return (
    <InputAreaWrapper
      ref={ref}
      inputAreaHeight={inputAreaHeight}
      className={mobileClass}
    >
      <TextArea
        ref={textAreaRef}
        className="textareaStyle"
        value={props.text}
        onChange={props.textOnChange}
        disabled={props.isMicRecording || props.disabled}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !isComposing) {
            if (e.currentTarget.value === "") {
              return;
            }
            props.handleOnSend();
          }
        }}
        onCompositionStart={() => setIsComposing(true)}
        onCompositionEnd={() => setIsComposing(false)}
      />
      <ButtonSend
        isMicRecording={props.isMicRecording || props.disabled}
        className="buttonSendMargin"
        onButtonDown={props.handleOnSend}
        disabled={props.disabled}
      />
      <ButtonMicroPhone
        className="buttonMicroPhoneMargin"
        isMicRecording={props.isMicRecording}
        onButtonDown={props.startRecording}
        onButtonUp={props.stopRecording}
      />
    </InputAreaWrapper>
  );
});
