// eslint-disable-next-line import/no-extraneous-dependencies
import "@/recoReset.css";
import "@/recoGlobal.css";
import "@/theme.scss";

import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "@/App";
import { getClientByClientCode } from "@/libs/apiClient";
import { ThemeProvider } from "@/providers/theme";
import { ParentSiteStateType } from "@/utils/atoms";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_URL,
  integrations: [new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
declare global {
  interface Window {
    ReCoInit: () => void;
  }
}

const ReCoInit = async (): Promise<void> => {
  document.body.style.margin = "0";
  const appRoot = document.createElement("div");
  appRoot.id = "reco-root";
  appRoot.style.width = "100vw";
  appRoot.style.height = "100dvh";
  document.body.appendChild(appRoot);

  const receivedUrlPromise = new Promise((resolve) => {
    const receiveMessage = (event: MessageEvent) => {
      if (event.data.type === "SET_URL") {
        resolve(event.data.url);
      }
    };
    window.addEventListener("message", receiveMessage);
  });

  const clientCodePromise = new Promise((resolve) => {
    const receiveClientCode = (event: MessageEvent) => {
      if (import.meta.env.VITE_CUSTOMER_CODE !== undefined) {
        resolve(import.meta.env.VITE_CUSTOMER_CODE);
      }

      if (event.data.type === "ClientCode") {
        resolve(event.data.clientCode);
      }

      // ローダーからcustomerCodeを取得する
      const loader = document.getElementById("reco-loader");
      if (loader?.dataset.recoCustomerCode !== undefined) {
        resolve(loader.dataset.recoCustomerCode);
      }
    };
    window.addEventListener("message", receiveClientCode);
  });

  const parentSiteStatePromise = new Promise((resolve) => {
    const receiveParentState = (event: MessageEvent) => {
      if (typeof event.data !== "object") return;
      if (event.data.source !== "reco-parent-site") return;
      if (event.data.state) {
        resolve(event.data.state);
      }
    };
    window.addEventListener("message", receiveParentState);
  });

  const [receivedUrl, clientCode, parentSiteState] = await Promise.all([
    receivedUrlPromise,
    clientCodePromise,
    parentSiteStatePromise,
  ]);

  // PCかSPかを判定する関数
  const isMobileDevice = () => window.innerWidth <= 768;

  let frameSize: {
    width: number | string;
    height: number | string;
  } = {
    width: "100%",
    height: "100%",
  };
  // 判定結果を使用して処理を分岐
  if (isMobileDevice()) {
    frameSize = {
      width: "100%",
      height: 110,
    };
  } else {
    frameSize = {
      width: "550px",
      height: "225px",
    };
  }

  const client = await getClientByClientCode(clientCode as string);

  ReactDOM.createRoot(appRoot).render(
    <React.StrictMode>
      <ThemeProvider>
        <App
          client={client}
          pUrl={receivedUrl as string}
          pss={parentSiteState as ParentSiteStateType}
          frameSize={frameSize}
        />
      </ThemeProvider>
    </React.StrictMode>,
  );
};

window.ReCoInit = ReCoInit;
ReCoInit();
