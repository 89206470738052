import { forwardRef } from "react";
import { GrPowerReset } from "react-icons/gr";
import styled from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background: rgba(242, 242, 242, 1);
    }
  }

  @media (hover: none) {
    &:active {
      background: rgba(242, 242, 242, 1);
    }
  }
`;

const StyledMicIcon = styled(GrPowerReset)`
  color: rgba(162, 162, 162, 1);
`;

export const ButtonReset = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    onButtonDown: () => void;
    onButtonUp: () => void;
  }
>(
  (
    {
      className,
      disabled,
      onClick,
      onMouseDown,
      onMouseUp,
      onButtonUp,
      onButtonDown,
    },
    ref,
  ) => {
    const handleButtonUp = () => {
      onButtonUp();
    };

    const handleButtonDown = () => {
      onButtonDown();
    };

    return (
      <Button
        className={className}
        type="button"
        disabled={disabled}
        ref={ref}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onPointerDown={handleButtonDown}
        onPointerUp={handleButtonUp}
        onTouchStart={handleButtonDown}
        onTouchEnd={handleButtonUp}
      >
        <StyledMicIcon size={22} />
      </Button>
    );
  },
);
