import { forwardRef } from "react";
import { FaMicrophone } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background: rgba(242, 242, 242, 1);
    }
  }

  @media (hover: none) {
    &:active {
      background: rgba(242, 242, 242, 1);
    }
  }
`;

const SoundWavesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`;

const waveAnimation = keyframes`
  0%, 100% { transform: scaleY(1); }
  50% { transform: scaleY(2); }
`;

const StyledSoundWaves = styled.div<{ delay: number }>`
  border: 1px solid rgba(55, 112, 255, 1);
  height: 12px;
  width: 2px;
  background-color: rgba(55, 112, 255, 1);
  border-radius: 100px;
  animation: ${waveAnimation} 1s ease-in-out infinite;
  animation-delay: ${({ delay }) => delay}s;
  margin: 0 1px;
`;

const StyledMicIcon = styled(FaMicrophone)`
  color: rgba(55, 112, 255, 1);
`;

export const PitaliyButtonMicroPhone = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    isMicRecording: boolean;
    onButtonDown: () => void;
    onButtonUp: () => void;
  }
>(
  (
    {
      className,
      isMicRecording,
      disabled,
      onClick,
      onMouseDown,
      onMouseUp,
      onButtonUp,
      onButtonDown,
    },
    ref,
  ) => {
    const handleButtonUp = () => {
      onButtonUp();
    };

    const handleButtonDown = () => {
      onButtonDown();
    };

    return (
      <Button
        className={className}
        type="button"
        disabled={disabled}
        ref={ref}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onPointerDown={handleButtonDown}
        onPointerUp={handleButtonUp}
        onTouchStart={handleButtonDown}
        onTouchEnd={handleButtonUp}
      >
        {isMicRecording ? (
          <SoundWavesWrapper>
            <StyledSoundWaves delay={0.1} />
            <StyledSoundWaves delay={0.3} />
            <StyledSoundWaves delay={0.5} />
            <StyledSoundWaves delay={0.7} />
            <StyledSoundWaves delay={0.9} />
          </SoundWavesWrapper>
        ) : (
          <StyledMicIcon size={22} />
        )}
      </Button>
    );
  },
);
