import * as Sentry from "@sentry/react";
import { useAtomValue, useSetAtom } from "jotai";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { ChatCommentArea } from "@/commands/Chat/components/ChatCommentArea";
import { InputArea } from "@/commands/Chat/components/ChatInput";
import { ChatNoComment } from "@/commands/Chat/components/ChatNoComments";
import { ChatEvaluationArea } from "@/commands/Chat/components/EvaluationArea";
import { useAreaHeight } from "@/commands/Chat/hooks/useAreaHeight";
import { useAtalker } from "@/commands/Chat/hooks/useAtalker";
import { useChatHistory } from "@/commands/Chat/hooks/useChatHistory";
import { useScrollToBottom } from "@/commands/Chat/hooks/useScrollToBottom";
import {
  BaseChatContainer,
  ChatAreaContainer,
  ChatAreaWrapper,
} from "@/commands/Chat/index.style";
import { CloseButton } from "@/components/CloseButton";
import { useVoiceInput } from "@/hooks/useVoiceInput";
import {
  characterMotionAtom,
  clientConfigAtom,
  currentCommandAtom,
} from "@/utils/atoms";

type Props = {
  oneWayDirection?: string;
  isMobile: boolean;
  imageLoaded: boolean;
  handleImageLoaded: () => void;
};

export function CognitiveChat({
  oneWayDirection,
  isMobile,
  imageLoaded,
  handleImageLoaded,
}: Props): JSX.Element {
  const clientConfig = useAtomValue(clientConfigAtom);
  const setCharacterMotion = useSetAtom(characterMotionAtom);
  const setCurrentCommand = useSetAtom(currentCommandAtom);

  const [isSearching, setIsSearching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [textInput, setTextInput] = useState<string>("");

  const areaHeightHook = useAreaHeight();

  const voiceInputHook = useVoiceInput({
    onResult: (text: string) => {
      setTextInput(text);
    },
    onStopRecording: (text: string) => {
      setTextInput(text);
    },
  });

  const chatHistoryHook = useChatHistory({
    isSearching,
    isError,
    isMicRecording: voiceInputHook.isMicRecording,
    clientId: clientConfig.id,
  });

  // コメントの追加で画面下にスクロールする処理
  const { chatAreaContainerRef } = useScrollToBottom({
    comments: chatHistoryHook.chatComments,
  });

  const atalkerHook = useAtalker({
    clientConfig,
    chatId: chatHistoryHook.chatId,
  });

  // ユーザの質問を処理する
  // 会話の記録とAPI呼び出し
  const handleSend = useCallback(
    async (question: string) => {
      if (question === "") {
        return;
      }
      setIsSearching(true);

      chatHistoryHook.appendChatComment({
        content: question,
        role: "user",
      });
      try {
        const res = await atalkerHook.getAnswer({
          userText: question,
          chatHistory: chatHistoryHook.chatHistory,
          chatId: chatHistoryHook.chatId,
        });
        if (!res?.chat_history) throw new Error();
        chatHistoryHook.setChatHistory(res.chat_history);
      } catch (e) {
        setIsError(true);
        Sentry.captureException(e);
      } finally {
        setIsSearching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [atalkerHook.getAnswer, chatHistoryHook],
  );

  const handleOnSend = useCallback(() => {
    setTextInput("");
    handleSend(textInput);
  }, [handleSend, textInput]);

  useEffect(() => {
    setCharacterMotion(isSearching ? "respectful" : "normal");
  }, [isSearching, setCharacterMotion]);

  const handleCloseBtn = () => {
    if (oneWayDirection !== "") {
      setCurrentCommand("sales");
    } else {
      setCurrentCommand("circle");
    }
  };

  return (
    <BaseChatContainer isMobile={isMobile}>
      <ChatAreaWrapper
        isMobile={isMobile}
        height={areaHeightHook.chatAreaHeight}
        inputAreaHeight={areaHeightHook.inputAreaHeight}
      >
        <CloseButton
          className="closeButtonPadding"
          onButtonDown={handleCloseBtn}
        />

        {chatHistoryHook.displayComments.length === 0 && (
          <ChatNoComment
            isMobile={isMobile}
            inputAreaHeight={areaHeightHook.inputAreaHeight}
            client={clientConfig}
          />
        )}
        <ChatAreaContainer isMobile={isMobile} ref={chatAreaContainerRef}>
          {chatHistoryHook.displayComments.map((comment, index: number) => (
            <ChatCommentArea
              key={`${comment.commentId}`}
              comment={comment}
              isMicRecording={voiceInputHook.isMicRecording}
              isLastComment={
                index === chatHistoryHook.displayComments.length - 1
              }
              isSearching={isSearching}
              isMobile={isMobile}
              imageLoaded={imageLoaded}
              handleImageLoaded={handleImageLoaded}
            />
          ))}

          {chatHistoryHook.displayComments.length > 0 && !isSearching && (
            <ChatEvaluationArea
              currentEvaluation={atalkerHook.currentEvaluation}
              handleOnGood={() => atalkerHook.handleOnClickEvaluation("good")}
              handleOnBad={() => atalkerHook.handleOnClickEvaluation("bad")}
              isMobile={isMobile}
            />
          )}
        </ChatAreaContainer>
      </ChatAreaWrapper>
      <InputArea
        ref={areaHeightHook.inputAreaRef}
        handleOnSend={handleOnSend}
        isMicRecording={voiceInputHook.isMicRecording}
        disabled={isSearching}
        text={textInput}
        textOnChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setTextInput(e.target.value)
        }
        startRecording={voiceInputHook.startRecording}
        stopRecording={voiceInputHook.stopRecording}
        isMobile={isMobile}
      />
    </BaseChatContainer>
  );
}
