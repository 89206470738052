import styled from "styled-components";

import { ButtonModal } from "@/components/ButtonModal";
import CancelIcon from "@/public/cancel.svg";

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ModalWrapper = styled.div`
  width: 358px;
  height: 281px;
  border-radius: 16px;
  gap: 8px;
`;

const CancelRoundButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.2);
`;

const CancelRoundIcon = styled.img``;

const InfoText = styled.p`
  color: rgba(28, 28, 28, 1);
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  margin: 0 24px;
`;

type Props = {
  onCancel: () => void;
  onClick: () => void;
};

export function ResetConfirmModal({ onCancel, onClick }: Props): JSX.Element {
  return (
    <Overlay>
      <ModalWrapper>
        <CancelRoundButton onClick={onCancel}>
          <CancelRoundIcon src={CancelIcon} />
        </CancelRoundButton>

        <InfoText>
          会話を初めからやり直します。
          <br />
          よろしいですか？
        </InfoText>

        <ButtonWrapper>
          <ButtonModal buttonType="cancel" onClick={onClick} />
          <ButtonModal buttonType="reset" onClick={onClick} />
        </ButtonWrapper>
      </ModalWrapper>
    </Overlay>
  );
}
