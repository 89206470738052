import axios from "axios";

import { type ChatResponse } from "@/commands/ChatAtoffice/types";

interface MessagePostBody {
  chat_text: string;
  chat_history: { role: "user" | "assistant"; content: string }[];
}
interface ReturnHookType {
  postMessage: (body: MessagePostBody) => Promise<ChatResponse>;
}
const useAtofficeRecommend = (): ReturnHookType => {
  const atofficeEndpoint =
    import.meta.env.VITE_ENV === "local"
      ? "http://localhost:8000/api/atoffice/chat_atoffice"
      : "https://a-talker-dev.onrender.com/api/atoffice/chat_atoffice";
  const postMessage = async (body: MessagePostBody): Promise<ChatResponse> => {
    const response = await axios.post(atofficeEndpoint, body, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  };
  return { postMessage };
};

export default useAtofficeRecommend;
