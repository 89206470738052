import { useCallback, useEffect, useState } from "react";

import {
  AssistantEndButton,
  AssistantEndButtonWrapper,
  AssistantSwitchButtonWrapper,
  AssistantSwitchButton,
  AssistantSwitchText,
  AssistantSwitchIcon,
  AssistantSwitchImage,
  AssistantStartButton,
  AssistantStartWrapper,
} from "@/commands/ForExhibition/exhibitionAssistant.style";
import { ExhibitionChatArea } from "@/commands/ForExhibition/exhibitionChatAreaComponent";
import { ExhibitionMicArea } from "@/commands/ForExhibition/exhibitionMicAreaComponent";
import { useMiibo } from "@/hooks/useMiibo";
import SwitchIcon from "@/public/forExhibition/switchIcon.svg";
import SwitchIconActive from "@/public/forExhibition/switchIconActive.svg";

type Props = {
  clientId: string;
  clientCode: string;
};

export function ExhibitionAssistant({ clientId, clientCode }: Props) {
  const miiboHooks = useMiibo({
    code: clientId,
  });
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isSwitchClicked, setIsSwitchClicked] = useState<boolean>(false);
  useState<boolean>(false);
  const assistantEnd = useCallback(() => {
    setIsStarted(false);
    miiboHooks.reset();
  }, [miiboHooks]);

  const assistantSwitch = () => {
    setIsSwitchClicked(true);
    if (clientCode === "rester") {
      window.parent.location.href = "https://aivy.tokyo/ceatec-kiosk";
    } else if (clientCode === "ceatec") {
      window.parent.location.href = "https://aivy.tokyo/rester-kiosk";
    }
  };

  useEffect(() => {
    let lastInteractionTime = Date.now();
    const handleUserInteraction = () => {
      lastInteractionTime = Date.now();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);

    const interval = setInterval(() => {
      if (!miiboHooks.isSpeaking) {
        if (Date.now() - lastInteractionTime > 300000) {
          if (isStarted) {
            assistantEnd();
          } else {
            window.location.reload();
          }
        } else {
          handleUserInteraction();
        }
      }
    }, 300000);

    return () => {
      clearInterval(interval);
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
    };
  }, [assistantEnd, isStarted, miiboHooks.isSpeaking]);

  if (!isStarted) {
    return (
      <AssistantStartWrapper>
        <AssistantStartButton
          type="button"
          onClick={() => {
            setIsStarted(true);
            miiboHooks.startMiibo();
          }}
        >
          会話を始める
        </AssistantStartButton>
      </AssistantStartWrapper>
    );
  }

  return (
    <>
      <AssistantEndButtonWrapper>
        <AssistantEndButton type="button" onClick={assistantEnd}>
          会話を終了する
        </AssistantEndButton>
      </AssistantEndButtonWrapper>
      <AssistantSwitchButtonWrapper>
        <AssistantSwitchButton
          type="button"
          onClick={assistantSwitch}
          clientCode={clientCode}
        >
          <AssistantSwitchText clientCode={clientCode}>
            {clientCode === "rester" ? "CEATEC" : "レスター"}
          </AssistantSwitchText>
          <AssistantSwitchIcon
            clientCode={clientCode}
            isSwitchClicked={isSwitchClicked}
          >
            <AssistantSwitchImage
              src={isSwitchClicked ? SwitchIconActive : SwitchIcon}
            />
          </AssistantSwitchIcon>
        </AssistantSwitchButton>
      </AssistantSwitchButtonWrapper>

      <ExhibitionMicArea miiboHooks={miiboHooks} clientCode={clientCode} />
      <ExhibitionChatArea miiboHooks={miiboHooks} />
    </>
  );
}
