import { useState } from "react";
import ReactMarkdown, { Components } from "react-markdown";

import {
  AssistantCommentWrapper,
  AssistantComment,
  CommentLoading,
  UserSelectButtonsWrapper,
  UserSelectButton,
  UserActionWrapper,
  EndSpeakingWording,
} from "@/commands/Assistant/signageMode.style";
import { SignageButtonMicroPhone } from "@/components/SignageButtonMicroPhone";
import { MiiboHookTypes } from "@/hooks/useMiibo";
import { useVoiceInput } from "@/hooks/useVoiceInput";

type Props = {
  miiboHooks: MiiboHookTypes;
};

const LinkRenderer: Components["a"] = function LinkRenderer({
  href,
  children,
}) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const OlRenderer: Components["ol"] = function OlRenderer({ children }) {
  return <ol style={{ paddingLeft: "40px" }}>{children}</ol>;
};

export function SignageAssistantChatArea({ miiboHooks }: Props) {
  const [textInput, setTextInput] = useState<string>("");

  const voiceInputHooks = useVoiceInput({
    onResult: (text: string) => {
      setTextInput(text);
    },
    onStopRecording: (text: string) => {
      setTextInput(text);
      miiboHooks.sendChat(textInput);
    },
  });

  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  return (
    <AssistantCommentWrapper>
      <UserActionWrapper>
        <SignageButtonMicroPhone
          isMicRecording={voiceInputHooks.isMicRecording}
          onTouchStart={() => {
            if (voiceInputHooks.isMicRecording) {
              voiceInputHooks.stopRecording();
            } else {
              voiceInputHooks.startRecording();
            }
          }}
        />
        <EndSpeakingWording>
          {voiceInputHooks.isMicRecording &&
            "喋り終わったらマイクボタンを押してね！"}
        </EndSpeakingWording>
      </UserActionWrapper>
      {!miiboHooks.isLoading && (
        <AssistantComment
          tabIndex={0}
          role="button"
          style={{ cursor: "pointer" }}
        >
          <ReactMarkdown components={{ a: LinkRenderer, ol: OlRenderer }}>
            {miiboHooks.lastAssistantMessage}
          </ReactMarkdown>
        </AssistantComment>
      )}

      {miiboHooks.isLoading && (
        <AssistantComment>
          <CommentLoading />
        </AssistantComment>
      )}

      <UserSelectButtonsWrapper>
        {miiboHooks.chatOptions.map((option) => (
          <UserSelectButton
            key={option.value}
            type="button"
            isButtonVisible={isButtonVisible}
            onClick={() => {
              setIsButtonVisible(false);
              miiboHooks.sendChat(option.value);
            }}
          >
            {option.label}
          </UserSelectButton>
        ))}
      </UserSelectButtonsWrapper>
    </AssistantCommentWrapper>
  );
}
