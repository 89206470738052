import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const BaseChatContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  // height: 536px;
  height: calc(100% - 20px);
  text-align: left;
  border-radius: 0.5rem;
  animation: ${fadeIn} 0.6s ease;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  ${(props) =>
    props.isMobile &&
    `
    width: 100%;
    height: 100%;
    border-radius: 0px;
    bottom: 0;
    right: 0;
    `}
`;

export const ChatAreaWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["isMobile", "height", "inputAreaHeight"].includes(prop),
})<{
  isMobile: boolean;
  height: string;
  inputAreaHeight: string;
}>`
  position: relative;
  width: 400px;
  height: ${(props) => `${props.height}px`};
  background: #f7fafc;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${(props) =>
    props.isMobile &&
    `
    width: 100%;
    height: calc(100vh - ${props.inputAreaHeight}px);
    border-radius: 0px;
  `}

  .closeButtonPadding {
    top: 12px;
    right: 12px;

    ${(props) =>
      props.isMobile &&
      `
      top: 8px;
      right: 8px;
    `}
  }
`;

export const ChatAreaContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  overflow-y: auto;
  padding: 0 24px 0 10px;
  width: 100%;

  .commentMargin {
    margin-top: 16px;
  }

  .ButtonSelectAreaMargin {
    margin: 8px 0 0 73px;

    ${(props) =>
      props.isMobile &&
      `
      margin: 8px 0 0 53px;
    `}
  }

  .ButtonSelectMargin {
    margin: 4px 0 0 4px;
  }
`;
