import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import { useAtomValue, useSetAtom } from "jotai";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import s from "@/commands/ChatMiibo/ChatMiibo.module.scss";
import { InputArea } from "@/commands/ChatMiibo/components/ChatInput";
import ChatTemplate from "@/commands/ChatMiibo/components/ChatTemplate";
import { useAreaHeight } from "@/commands/ChatMiibo/hooks/useAreaHeight";
import { useMiibo } from "@/hooks/useMiibo";
import { useVoiceInput } from "@/hooks/useVoiceInput";
import { ClientSchema } from "@/types/client";
import { clientConfigAtom, currentCommandAtom } from "@/utils/atoms";

interface ChatMiiboRenderProps {
  isMobile: boolean;
  clientConfig: ClientSchema;
}
function ChatMiiboRender({ isMobile, clientConfig }: ChatMiiboRenderProps) {
  const areaHeightHook = useAreaHeight();
  const setCurrentCommand = useSetAtom(currentCommandAtom);

  const handleCloseBtn = () => {
    setCurrentCommand("chat_atoffice_entrance");
  };

  const [textInput, setTextInput] = useState<string>("");

  const voiceInputHook = useVoiceInput({
    onResult: (text: string) => {
      setTextInput(text);
    },
    onStopRecording: (text: string) => {
      setTextInput(text);
    },
  });

  // useMiibo
  const miiboHooks = useMiibo({
    code: clientConfig.id,
  });

  const isStartedMiibo = useRef(false);
  useEffect(() => {
    if (!isStartedMiibo.current) {
      miiboHooks.startMiibo(false);
      isStartedMiibo.current = true;
    }
  }, [miiboHooks]);

  const handleOnSend = useCallback(() => {
    setTextInput("");
    miiboHooks.sendChat(textInput);
  }, [textInput, miiboHooks]);

  const { isLoading } = miiboHooks;
  return (
    <div className={[s.ChatMiibo, isMobile ? s.isMobile : undefined].join(" ")}>
      <div
        className={[s.ChatAreaWrapper].join(" ")}
        style={{
          height: isMobile
            ? `${areaHeightHook.inputAreaHeight}px`
            : `${areaHeightHook.chatAreaHeight}px`,
        }}
      >
        <ChatTemplate
          client={clientConfig}
          handleCloseBtn={handleCloseBtn}
          chatLog={miiboHooks.chatLog}
          reset={miiboHooks.reset}
          sendChat={miiboHooks.sendChat}
          isLoading={miiboHooks.isLoading}
          options={miiboHooks.options}
        />
      </div>
      <InputArea
        ref={areaHeightHook.inputAreaRef}
        handleOnSend={handleOnSend}
        isMicRecording={voiceInputHook.isMicRecording}
        disabled={isLoading}
        text={textInput}
        textOnChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setTextInput(e.target.value)
        }
        startRecording={voiceInputHook.startRecording}
        stopRecording={voiceInputHook.stopRecording}
        isMobile={isMobile}
      />
    </div>
  );
}

type Props = {
  isMobile: boolean;
};

export default function ChatMiibo({ isMobile }: Props): JSX.Element {
  const clientConfig = useAtomValue(clientConfigAtom);

  return <ChatMiiboRender isMobile={isMobile} clientConfig={clientConfig} />;
}
