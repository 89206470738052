import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";

import s from "@/commands/ChatAtoffice/components/ChatAtofficeEntrance/ChatAtofficeEntrance.module.scss";
import CtaButton from "@/commands/ChatAtoffice/components/CtaButton";
import { clientConfigAtom, currentCommandAtom } from "@/utils/atoms";

export default function ChatAtofficeEntrance(): JSX.Element {
  const setCurrentCommand = useSetAtom(currentCommandAtom);
  const clientConfig = useAtomValue(clientConfigAtom);
  const [animationClass, setAnimationClass] = useState("RightIn");

  return (
    <div
      className={[
        s.ChatAtofficeEntrance,
        animationClass ? s[animationClass] : undefined,
      ].join(" ")}
    >
      <header>
        <button
          className={s._back_icon}
          data-tip="閉じる"
          type="button"
          aria-label="Close"
          onClick={() => {
            setAnimationClass("RightOut");
            setTimeout(() => setCurrentCommand("sales"), 300);
          }}
        >
          <IoMdCloseCircle />
        </button>
      </header>
      <main>
        <h3>
          <span>👋こんにちは!</span>
          アット執事です
        </h3>
        <h4>
          オフィス選びに関する質問があれば
          <br />
          何でもお答えします!
        </h4>
        <div className={s.character_wrapper}>
          <img src={clientConfig.character.normal} alt="" />
        </div>
        <div className={s.buttons_wrapper}>
          <CtaButton
            onClick={() => {
              setAnimationClass("RightOut");
              setTimeout(() => setCurrentCommand("chat_miibo"), 300);
            }}
            text="なんでも聞いてね！"
            color1="#85afb7"
            color2="#33A3B9"
          />
          <CtaButton
            onClick={() => {
              setAnimationClass("RightOut");
              setTimeout(() => setCurrentCommand("chat_atoffice"), 300);
            }}
            text="物件探しを手伝って"
            color1="#ce4128"
            color2="#FF3258"
          />
        </div>
      </main>
    </div>
  );
}
