"use client";

import { format, parse } from "date-fns";
import { useState, useRef } from "react";
import { FaCheck } from "react-icons/fa6";
import { GrSchedules } from "react-icons/gr";
import { IoIosArrowForward } from "react-icons/io";

import ChararecSchedule from "@/commands/ChatAtoffice/components/ChararecSchedule";
import s from "@/commands/ChatAtoffice/components/MessageItem/MessageItem.module.scss";
import RecommendItem from "@/commands/ChatAtoffice/components/RecommendItem";
import { CHARACTER_RECRUIT_URL } from "@/commands/ChatAtoffice/sample.data";
import { ChatResponse } from "@/commands/ChatAtoffice/types";
import { ClientSchema } from "@/types/client";

interface Props {
  item: ChatResponse;
  client: ClientSchema;
  sendUserMessage: (userMessage: string) => void;
  scrollToBottom: () => void;
}
function MessageItem({ item, client, sendUserMessage, scrollToBottom }: Props) {
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  function handleTag(tag: string) {
    setSelectedTag(tag);
    sendUserMessage(`${tag}で探しています`);
  }
  const history = item.chat_history;
  const lastHistory = history[history.length - 1];
  const userMessage =
    lastHistory?.role === "user" ? lastHistory.content : undefined;

  const carouselRef = useRef<HTMLDivElement>(null);

  // scroll carousel
  const [scrollPosition, setScrollPosition] = useState<"left" | "right" | null>(
    "left",
  );
  function scrollCarousel(direction: "next" | "prev") {
    if (!carouselRef.current) return;
    const scrollAmount = carouselRef.current.clientWidth;

    if (direction === "next") {
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    } else if (direction === "prev") {
      carouselRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
    setTimeout(() => {
      if (!carouselRef.current) return;
      const { scrollLeft } = carouselRef.current;
      const maxScroll =
        carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
      if (scrollLeft < 100) {
        setScrollPosition("left");
      } else if (maxScroll - scrollLeft < 250) {
        setScrollPosition("right");
      } else {
        setScrollPosition(null);
      }
    }, 300);
  }

  // timestamp
  const timestamp = item.timestamp
    ? parse(item.timestamp, "yyyy-MM-dd HH:mm:ss", new Date())
    : new Date();
  const formattedTimestamp = format(timestamp, "HH:mm");

  return (
    <div className={s.MessageItem}>
      {/* user message */}
      {userMessage && (
        <div className={[s._row_chat_text, s._user].join(" ")}>
          <div className={s._chat_text}>
            <p>
              {userMessage}
              <span className={s._timestamp}>{formattedTimestamp}</span>
            </p>
          </div>
        </div>
      )}

      {/* reco message */}
      {item.chat_text && !item.suggest_schedules_flg && (
        <div className={[s._row_chat_text, s._reco].join(" ")}>
          <div className={s._profile_wrapper}>
            <img src={client.character.attention} alt={client.name} />
          </div>
          <div className={s._chat_text}>
            <div className={s._display_name}>{client.name}</div>
            <p>
              {item.chat_text}
              {item.recommends.length === 0 && (
                <span className={s._timestamp}>{formattedTimestamp}</span>
              )}
            </p>
          </div>
        </div>
      )}

      {/* suggest_schedules */}
      {/* TODO: スケジュール選択の組み込みがpending中 */}
      {item.suggest_schedules.length > 0 && false && (
        <ChararecSchedule
          schedules={item.suggest_schedules}
          sendUserMessage={sendUserMessage}
          scrollToBottom={scrollToBottom}
        />
      )}
      {item.suggest_schedules_flg && (
        <div className={[s._row_chat_text, s._reco].join(" ")}>
          <div className={s._profile_wrapper}>
            <img src={client.character.normal} alt={client.name} />
          </div>
          <div className={s._chat_text}>
            <div className={s._display_name}>{client.name}</div>
            <p>
              ありがとうございます！あと少しで予約完了です🎉
              <br />
              下記ボタンから必要な情報を入力していただくだけですぐにご予約確定します！👇
            </p>
            <a href={CHARACTER_RECRUIT_URL} target="_blank" rel="noreferrer">
              <GrSchedules />
              簡単予約はこちら
            </a>
          </div>
        </div>
      )}

      {/* recommends */}
      {item.recommends.length > 0 && (
        <div className={s._row_recommends}>
          <div
            ref={carouselRef}
            className={`${s._scroll_wrapper} carousel snap-x snap-mandatory overflow-x-auto`}
          >
            {item.recommends.map((rec) => (
              <div
                // FIXME need unique id for key
                key={`tag-${rec.building_name}-${rec.address}`}
                className="carousel-item snap-start"
              >
                <RecommendItem item={rec} sendUserMessage={sendUserMessage} />
              </div>
            ))}
          </div>
          <div className="flex justify-between absolute top-1/2 w-full transform -translate-y-1/2 p-2">
            <button
              className={`${s._carousel_btn} btn btn-circle btn-sm`}
              disabled={scrollPosition === "left"}
              onClick={() => scrollCarousel("prev")}
              type="button"
            >
              ❮
            </button>

            <button
              className={`${s._carousel_btn} btn btn-circle btn-sm`}
              disabled={scrollPosition === "right"}
              onClick={() => scrollCarousel("next")}
              type="button"
            >
              ❯
            </button>
          </div>
        </div>
      )}

      {/* suggest tags */}
      {item.suggest_tags.length > 0 && (
        <div className={s._row_suggest_tags}>
          <p>次のような質問もできます</p>
          <div className={s._tags_scroll_area}>
            {item.suggest_tags.map((tag, index) => (
              <div
                // FIXME need unique id for key
                key={`tag-${tag}`}
                className={[
                  s._tag,
                  selectedTag === tag ? s._active : undefined,
                ].join(" ")}
                onClick={() => handleTag(tag)}
                role="button"
                tabIndex={index}
              >
                <p>{tag}</p>
                {selectedTag === tag ? <FaCheck /> : <IoIosArrowForward />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default MessageItem;
