import { useState, useEffect, useRef } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { MdKeyboardArrowUp } from "react-icons/md";
import ReactMarkdown, { Components } from "react-markdown";

import { Input } from "@/commands/AssistantOnly/Input";
import s from "@/commands/AssistantOnly/chatAreaComponent.module.scss";
import { SelectButton } from "@/commands/AssistantOnly/selectButton";
import { ButtonReset } from "@/components/ButtonReset";
import { PitaliyButtonMicroPhone } from "@/components/PitaliyButtonMicroPhone";
import { PitaliyButtonSend } from "@/components/PitaliyButtonSend";
import { ResetConfirmModal } from "@/components/ResetConfirmModal";
import { MiiboHookTypes } from "@/hooks/useMiibo";
import { useVoiceInput } from "@/hooks/useVoiceInput";

type Props = {
  isChatLogExpanded: boolean;
  setIsChatLogExpanded: (val: boolean) => void;
  miiboHooks: MiiboHookTypes;
  isMobile: boolean;
  hasAssistantBanner: boolean;
  hasBannerLink: boolean;
};

const LinkRenderer: Components["a"] = function LinkRenderer({
  href,
  children,
}) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const OlRenderer: Components["ol"] = function OlRenderer({ children }) {
  return <ol style={{ paddingLeft: "10px" }}>{children}</ol>;
};

export function AssistantChatArea({
  isChatLogExpanded,
  setIsChatLogExpanded,
  miiboHooks,
  isMobile,
  hasAssistantBanner,
  hasBannerLink,
}: Props) {
  const [textInput, setTextInput] = useState<string>("");
  const voiceInputHooks = useVoiceInput({
    onResult: (text: string) => {
      setTextInput(text);
    },
    onStopRecording: (text: string) => {
      setTextInput(text);
    },
  });

  const [isComposing, setIsComposing] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const CommentAreaRef = useRef<HTMLDivElement>(null);
  const [needShowChatLogButton, setNeedShowChatLogButton] = useState(false);

  const [resetModalOpen, setResetModalOpen] = useState(false);

  const toggleResetModal = () => {
    setResetModalOpen((prevState) => !prevState);
  };

  const handleReset = () => {
    miiboHooks.reset();
  };

  useEffect(() => {
    setTimeout(() => {
      if (scrollAreaRef.current && isChatLogExpanded) {
        scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
      }
    }, 100);
  }, [isChatLogExpanded]);

  useEffect(() => {
    if (!CommentAreaRef.current) return;
    const { children } = CommentAreaRef.current;
    const totalChildrenHeight = Array.from(children).reduce(
      (total, child) => total + (child as HTMLElement).offsetHeight,
      0,
    );
    if (totalChildrenHeight <= CommentAreaRef.current.offsetHeight)
      setNeedShowChatLogButton(false);
    else setNeedShowChatLogButton(true);
  }, [miiboHooks.chatLog]);

  return (
    <>
      {resetModalOpen && (
        <ResetConfirmModal
          onCancel={toggleResetModal}
          onClick={() => {
            // リセット処理をここに追加
            toggleResetModal();
          }}
        />
      )}
      <div
        className={[
          s.ChatAreaWrapper,
          isChatLogExpanded ? s.active : undefined,
        ].join(" ")}
      >
        <div
          className={s.CloseButton}
          onClick={() => {
            setIsChatLogExpanded(false);
          }}
        >
          <IoMdCloseCircle />
        </div>
        <div className={s.ScrollArea} ref={scrollAreaRef}>
          {miiboHooks.chatLog.map((chatLog) => (
            <div
              className={[s.ChatLogWrapper, s[`${chatLog.role}`]].join(" ")}
              key={`chatLog-${chatLog.content}`}
            >
              <div
                className={[s.ChatLogContent, s[`${chatLog.role}`]].join(" ")}
              >
                <ReactMarkdown components={{ a: LinkRenderer, ol: OlRenderer }}>
                  {chatLog.content}
                </ReactMarkdown>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={[
          s.AssistantCommentWrapper,
          !isMobile && hasAssistantBanner && hasBannerLink
            ? s._ex_mb
            : undefined,
          !isChatLogExpanded ? s.active : undefined,
        ].join(" ")}
      >
        <div className={s.CommentArea} ref={CommentAreaRef}>
          {miiboHooks.chatLog.map((chatLog) => (
            <div
              className={[
                s.ChatLogWrapper,
                s.FadeIn,
                s[`${chatLog.role}`],
              ].join(" ")}
              key={`chatLog-${chatLog.content}`}
            >
              <div
                className={[s.ChatLogContent, s[`${chatLog.role}`]].join(" ")}
              >
                <ReactMarkdown components={{ a: LinkRenderer, ol: OlRenderer }}>
                  {chatLog.content}
                </ReactMarkdown>
              </div>
            </div>
          ))}
          {miiboHooks.isLoading && (
            <div className={s.ChatLogWrapper}>
              <div className={[s.ChatLogContent, s.LoadingWrapper].join(" ")}>
                <div className={s.CommentLoading} />
              </div>
            </div>
          )}
          <div className={s.UserSelectButtonsWrapper}>
            {miiboHooks.chatOptions.map((option) => (
              <SelectButton
                key={`select-button-${option.value}`}
                option={option}
                isButtonVisible={isButtonVisible}
                onClick={() => {
                  setIsButtonVisible(false);
                  miiboHooks.sendChat(option.value);
                }}
              />
            ))}
          </div>
        </div>
        {needShowChatLogButton && (
          <div
            className={s.ChatLogButton}
            onClick={() => setIsChatLogExpanded(true)}
          >
            <span>チャットを見る</span> <MdKeyboardArrowUp />
          </div>
        )}

        <div className={s.UserActionWrapper}>
          <ButtonReset
            className={s.buttonResetMargin}
            onButtonDown={handleReset}
            onButtonUp={handleReset}
          />
          <Input
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            disabled={voiceInputHooks.isMicRecording}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !isComposing) {
                if (e.currentTarget.value === "") {
                  return;
                }
                miiboHooks.sendChat(textInput);
                setTextInput("");
              }
            }}
            onCompositionStart={() => setIsComposing(true)}
            onCompositionEnd={() => setIsComposing(false)}
          />
          <PitaliyButtonSend
            isMicRecording={voiceInputHooks.isMicRecording}
            className={s.buttonSendMargin}
            onButtonDown={() => {
              miiboHooks.sendChat(textInput);
              setTextInput("");
            }}
            disabled={voiceInputHooks.isMicRecording}
          />
          <PitaliyButtonMicroPhone
            className={s.buttonMicroPhoneMargin}
            isMicRecording={voiceInputHooks.isMicRecording}
            onButtonDown={voiceInputHooks.startRecording}
            onButtonUp={voiceInputHooks.stopRecording}
          />
        </div>
      </div>
    </>
  );
}
