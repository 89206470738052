import { forwardRef } from "react";
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 4px;
  position: absolute;
`;

const StyledIoIosClose = styled(IoIosClose)`
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #000;
  color: #000;
`;
const StyledIoIosCloseWhite = styled(IoIosClose)`
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #fff;
`;

export const CloseButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    onButtonDown: () => void;
    isWhite?: boolean;
  }
>(
  (
    { disabled, className, onClick, onMouseDown, onButtonDown, isWhite },
    ref,
  ) => {
    const handleButtonDown = () => {
      onButtonDown();
    };

    return (
      <Button
        type="button"
        className={className}
        disabled={disabled}
        ref={ref}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onPointerDown={handleButtonDown}
        onTouchStart={handleButtonDown}
      >
        {isWhite ? <StyledIoIosCloseWhite /> : <StyledIoIosClose />}
      </Button>
    );
  },
);
