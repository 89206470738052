import { forwardRef } from "react";
import { IoIosSend } from "react-icons/io";
import styled from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background: rgba(242, 242, 242, 1);
    }
  }

  @media (hover: none) {
    &:active {
      background: rgba(242, 242, 242, 1);
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: #e1eef3;
  }
`;

const StyledIoIosSend = styled(IoIosSend)`
  color: rgba(55, 112, 255, 1);
  width: 18px;
  height: 18px;
`;

export const PitaliyButtonSend = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    onButtonDown: () => void;
    isMicRecording: boolean;
  }
>(({ className, isMicRecording, onClick, onMouseDown, onButtonDown }, ref) => {
  const handleButtonDown = () => {
    onButtonDown();
  };

  return (
    <Button
      className={className}
      type="button"
      disabled={isMicRecording}
      ref={ref}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onPointerDown={handleButtonDown}
      onTouchStart={handleButtonDown}
    >
      <StyledIoIosSend />
    </Button>
  );
});
