import { useState } from "react";
import ReactMarkdown, { Components } from "react-markdown";

import {
  AssistantCommentWrapper,
  AssistantComment,
  CommentLoading,
  UserSelectButtonsWrapper,
  UserSelectButton,
} from "@/commands/ForExhibition/exhibitionChatArea.style";
import { MiiboHookTypes } from "@/hooks/useMiibo";

type Props = {
  miiboHooks: MiiboHookTypes;
};

const LinkRenderer: Components["a"] = function LinkRenderer({
  href,
  children,
}) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const OlRenderer: Components["ol"] = function OlRenderer({ children }) {
  return <ol style={{ paddingLeft: "40px" }}>{children}</ol>;
};

export function ExhibitionChatArea({ miiboHooks }: Props) {
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);

  return (
    <AssistantCommentWrapper>
      {!miiboHooks.isLoading && (
        <AssistantComment
          tabIndex={0}
          role="button"
          style={{ cursor: "pointer" }}
        >
          <ReactMarkdown components={{ a: LinkRenderer, ol: OlRenderer }}>
            {miiboHooks.lastAssistantMessage}
          </ReactMarkdown>
        </AssistantComment>
      )}

      {miiboHooks.isLoading && (
        <AssistantComment>
          <CommentLoading />
        </AssistantComment>
      )}

      <UserSelectButtonsWrapper>
        {miiboHooks.chatOptions.map((option) => (
          <UserSelectButton
            key={option.value}
            type="button"
            isButtonVisible={isButtonVisible}
            onClick={() => {
              setIsButtonVisible(false);
              miiboHooks.sendChat(option.value);
            }}
          >
            {option.label}
          </UserSelectButton>
        ))}
      </UserSelectButtonsWrapper>
    </AssistantCommentWrapper>
  );
}
