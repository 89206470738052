import { forwardRef } from "react";
import { FaMicrophone } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

import { ButtonProps } from "@/utils/types";

const DefaultColor = `
  background: var(--button-background);
  transition: all 0.3s ease;
`;

const RecordingColor = `
  background: var(--button-background);
  transition: all 0.3s ease;
`;

const Button = styled.button<{ $isMicRecording: boolean }>`
  border-radius: 50%;
  border: none;
  width: 48px;
  height: 48px;
  padding: 6px 7.7px 6px 7.3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  ${({ $isMicRecording }) => ($isMicRecording ? RecordingColor : DefaultColor)};
`;

const SoundWavesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`;

const waveAnimation = keyframes`
  0%, 100% { transform: scaleY(1); }
  50% { transform: scaleY(2); }
`;

const StyledSoundWaves = styled.div<{ delay: number }>`
  border: 1px solid #fff;
  height: 12px;
  width: 2px;
  background-color: #fff;
  border-radius: 100px;
  animation: ${waveAnimation} 1s ease-in-out infinite;
  animation-delay: ${({ delay }) => delay}s;
`;

const StyledMicIcon = styled(FaMicrophone)`
  color: #fff;
`;

export const ButtonMicroPhone = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    isMicRecording: boolean;
    onButtonDown: () => void;
    onButtonUp: () => void;
  }
>(
  (
    {
      className,
      isMicRecording,
      disabled,
      onClick,
      onMouseDown,
      onMouseUp,
      onButtonUp,
      onButtonDown,
    },
    ref,
  ) => {
    const handleButtonUp = () => {
      onButtonUp();
    };

    const handleButtonDown = () => {
      onButtonDown();
    };

    return (
      <Button
        className={className}
        $isMicRecording={isMicRecording}
        type="button"
        disabled={disabled}
        ref={ref}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onPointerDown={handleButtonDown}
        onPointerUp={handleButtonUp}
        onTouchStart={handleButtonDown}
        onTouchEnd={handleButtonUp}
      >
        {isMicRecording ? (
          <SoundWavesWrapper>
            <StyledSoundWaves delay={0.1} />
            <StyledSoundWaves delay={0.3} />
            <StyledSoundWaves delay={0.5} />
            <StyledSoundWaves delay={0.7} />
            <StyledSoundWaves delay={0.9} />
          </SoundWavesWrapper>
        ) : (
          <StyledMicIcon size={22} />
        )}
      </Button>
    );
  },
);
