import styled, { keyframes } from "styled-components";

export const AssistantWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: #fff;
`;

export const BackgroundWrapper = styled.div<{ src: string }>`
  position: absolute;
  top: 96px;
  left: 128px;
  width: calc(100% - 256px);
  height: calc(100% - 192px);
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  border-radius: 100px;
  border: 8px solid #3e3a3a;
`;

export const LogoWrapper = styled.button`
  position: absolute;
  top: -8px;
  left: -10px;
  background: none;
  border: none;
  padding: 0;
  z-index: 999;
  background: #fff;
  border-radius: 80px 0 80px 0;
  border-right: 8px solid #3e3a3a;
  border-bottom: 8px solid #3e3a3a;
  padding: 0 96px 16px 32px;
`;

export const ChatAreaWrapper = styled.div`
  padding: 5rem 1.25rem;
  background-color: hsla(0, 0%, 100%, 0.5);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
`;

const ellipsisAnimation = keyframes`
  0% {
    content: "";
  }
  25% {
    content: "・";
  }
  50% {
    content: "・・";
  }
  75% {
    content: "・・・";
  }
  100% {
    content: "";
  }
`;

export const CommentLoading = styled.p`
  width: 40px;
  display: inline-block;
  &:after {
    content: "・・・";
    animation: ${ellipsisAnimation} 1s infinite;
  }

  justify-content: left;
`;

export const UserSelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const UserSelectButton = styled("button").withConfig({
  shouldForwardProp: (prop) => !["isButtonVisible"].includes(prop),
})<{ isButtonVisible: boolean }>`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 8px 12px;
  margin: 0.25rem 0.5rem;
  cursor: pointer;
  min-width: 70px;
  display: ${(props) => (props.isButtonVisible ? "block" : "none")};
  font-size: 12px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const UserActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 1rem 0;
  width: -webkit-fill-available;

  .textInputWidth {
    flex-grow: 1;
  }

  .buttonSendMargin {
    margin: 0 0 0 12px;
  }

  .buttonMicroPhoneMargin {
    margin: 0 0 0 8px;
  }
`;

export const AssistantStartButton = styled.button`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 16px 24px;
  margin: 0.5rem 1rem;
  cursor: pointer;
  min-width: 140px;
  font-size: 24px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const AssistantEndButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  max-width: 28rem;
  max-height: calc(100dvh - 100px);
`;

export const AssistantEndButton = styled.button`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 16px 24px;
  margin: 0.5rem 1rem;
  cursor: pointer;
  min-width: 140px;
  font-size: 24px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;
