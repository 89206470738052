import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

import Assistant from "@/commands/Assistant";
import AssistantOnly from "@/commands/AssistantOnly";
import { CognitiveChat } from "@/commands/Chat";
import ChatAtoffice, { ChatAtofficeEntrance } from "@/commands/ChatAtoffice";
import ChatMiibo from "@/commands/ChatMiibo";
import { Circle } from "@/commands/Circle";
import { Close } from "@/commands/Close";
import ForExhibition from "@/commands/ForExhibition";
import { RecoSalesCommand } from "@/commands/RecoSalesCommand";
import { useFrameControl } from "@/hooks/useFrameControl";
import { ClientSchema } from "@/types/client";
import {
  clientConfigAtom,
  currentCommandAtom,
  languageAtom,
  parenSiteStateAtom,
  ParentSiteStateType,
  parentUrlAtom,
} from "@/utils/atoms";

const wildcardMatch = (url: string, pattern: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const escapedPattern = pattern.replace(/([.+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  const regexPattern = escapedPattern.replace(/\*/g, ".*");
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(url);
};

const isDisplayIframe = (client: ClientSchema, parentUrl: string): boolean => {
  if (client.id === "") return false;
  if (!client.excludedUrls?.length) return true;
  const newUrl = new URL(parentUrl);
  const normalizedParentUrl = `${newUrl.origin}${newUrl.pathname}/`.replace(
    /\/+$/,
    "/",
  );
  const isExcluded = client.excludedUrls.some((url) =>
    wildcardMatch(`${normalizedParentUrl}`, `${url}`),
  );

  return !isExcluded;
};

type AppProps = {
  client: ClientSchema | null;
  pUrl: string;
  pss: ParentSiteStateType;
  frameSize: {
    width: number | string;
    height: number | string;
  };
};

function App({ client, pUrl, pss, frameSize }: AppProps) {
  const [clientConfig, setClientConfig] = useAtom(clientConfigAtom);
  const [parentUrl, setParentUrl] = useAtom(parentUrlAtom);
  const [parentSiteState, setParentSiteState] = useAtom(parenSiteStateAtom);

  useEffect(() => {
    if (client) {
      setClientConfig(client);
    }
    setParentUrl(pUrl);
    setParentSiteState(pss);
  }, [client, pUrl, pss, setClientConfig, setParentSiteState, setParentUrl]);

  const currentCommand = useAtomValue(currentCommandAtom);
  const parentWidth = parentSiteState.outerWidth;
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const [oneWayDirection, setOneWayDirection] = useState("");
  useEffect(() => {
    if (clientConfig.name === "atoffice") {
      setOneWayDirection("chat_atoffice_oneway");
    } else if (
      clientConfig.facebook ||
      clientConfig.faqUrl ||
      clientConfig.inquiryUrl ||
      clientConfig.instagram ||
      clientConfig.line ||
      clientConfig.tiktok ||
      clientConfig.xTwitter
    ) {
      setOneWayDirection("");
    } else if (
      clientConfig.commands?.length === 1 &&
      clientConfig.commands[0].includes("assistant")
    ) {
      setOneWayDirection("assistant");
    } else if (
      clientConfig.commands?.length === 1 &&
      clientConfig.commands[0].includes("chat")
    ) {
      setOneWayDirection("chat");
    }
  }, [
    clientConfig.name,
    clientConfig.commands,
    clientConfig.facebook,
    clientConfig.faqUrl,
    clientConfig.inquiryUrl,
    clientConfig.instagram,
    clientConfig.line,
    clientConfig.tiktok,
    clientConfig.xTwitter,
  ]);

  // 言語設定
  const setLanguage = useSetAtom(languageAtom);
  useEffect(() => {
    setLanguage(navigator.language);
  }, [setLanguage]);

  // スマホかPCかを判定
  const isMobile = useMemo(
    () => parentWidth !== undefined && parentWidth <= 567,
    [parentWidth],
  );

  // 親に表示しているRecoのiframe状態を変更
  const setFrameState = useFrameControl({
    style: {
      right: 0,
      bottom: 0,
      width: frameSize.width,
      height: frameSize.height,
    },
  });

  useEffect(() => {
    if (!isDisplayIframe(clientConfig, parentUrl)) {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: 0,
          height: 0,
        },
      });
      return;
    }

    // TODO:isForExhibitionと一つにする
    if (client?.isSignage) {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          backgroundImage:
            "https://images.microcms-assets.io/assets/325e16f164e94ee691aea2555f6f20d2/08e8a12b49ab455494bbf4849e3d0096/namixBackground.svg",
          backgroundSize: "cover",
          backgroundPosition: "center",
        },
      });
      return;
    }

    if (client?.isForExhibition) {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        },
      });
      return;
    }

    if (client?.isAssistantOnly) {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        },
      });
      return;
    }

    let width;
    let height;
    if (isMobile) {
      switch (currentCommand) {
        case "chat":
        case "chat_miibo":
        case "chat_atoffice_entrance":
        case "chat_atoffice":
          width = "100%";
          height = client?.bottomHeight
            ? `calc(100% - ${client.bottomHeight}px)`
            : "100%";
          break;
        case "assistant":
          width = "100%";
          height = client?.bottomHeight
            ? `calc(100% - ${client.bottomHeight}px)`
            : "100%";
          break;
        case "circle":
          width = "310px";
          height = "310px";
          break;
        case "close":
          width = 96;
          height = 60;
          break;
        default:
          width = "100%";
          height = 110;
          break;
      }
    } else {
      switch (currentCommand) {
        case "chat":
        case "chat_miibo":
        case "chat_atoffice_entrance":
        case "chat_atoffice":
          width = 415;
          height = 720;
          break;
        case "assistant":
          width = "100%";
          height = client?.bottomHeightPC
            ? `calc(100% - ${client.bottomHeightPC}px)`
            : "100%";
          break;
        case "circle":
          width = "500px";
          height = "350px";
          break;
        case "close":
          width = 130;
          height = 50;
          break;
        default:
          width = "550px";
          height = "225px";
          break;
      }
    }

    if (isMobile) {
      setFrameState({
        style: {
          right: 0,
          bottom: client?.bottomHeight ? client?.bottomHeight : 0,
          width,
          height,
        },
      });
    } else {
      setFrameState({
        style: {
          right: 0,
          bottom: client?.bottomHeightPC ? client?.bottomHeightPC : 0,
          width,
          height,
        },
      });
    }
  }, [
    setFrameState,
    isMobile,
    currentCommand,
    clientConfig,
    parentUrl,
    client?.isSignage,
    client?.isForExhibition,
    client?.isAssistantOnly,
    client?.bottomHeight,
    client?.bottomHeightPC,
  ]);

  if (client?.isSignage) {
    return (
      <Assistant
        oneWayDirection={oneWayDirection}
        imageLoaded={imageLoaded}
        handleImageLoaded={handleImageLoad}
        isMobile={isMobile}
      />
    );
  }

  if (client?.isForExhibition) {
    return (
      <ForExhibition
        imageLoaded={imageLoaded}
        handleImageLoaded={handleImageLoad}
      />
    );
  }

  if (client?.isAssistantOnly) {
    return (
      <AssistantOnly
        setFrameState={setFrameState}
        imageLoaded={imageLoaded}
        handleImageLoaded={handleImageLoad}
        isMobile={isMobile}
      />
    );
  }

  let content;
  switch (currentCommand) {
    case "assistant":
      content = (
        <Assistant
          oneWayDirection={oneWayDirection}
          imageLoaded={imageLoaded}
          handleImageLoaded={handleImageLoad}
          isMobile={isMobile}
        />
      );
      break;
    case "chat":
      content = (
        <CognitiveChat
          oneWayDirection={oneWayDirection}
          isMobile={isMobile}
          imageLoaded={imageLoaded}
          handleImageLoaded={handleImageLoad}
        />
      );
      break;
    case "chat_atoffice_entrance":
      content = <ChatAtofficeEntrance />;
      break;
    case "chat_atoffice":
      content = <ChatAtoffice isMobile={isMobile} />;
      break;
    case "chat_miibo":
      content = <ChatMiibo isMobile={isMobile} />;
      break;
    case "circle":
      content = (
        <Circle
          isMobile={isMobile}
          imageLoaded={imageLoaded}
          handleImageLoaded={handleImageLoad}
        />
      );
      break;
    case "close":
      content = (
        <Close
          isMobile={isMobile}
          imageLoaded={imageLoaded}
          handleImageLoad={handleImageLoad}
        />
      );
      break;
    default:
      content = (
        <RecoSalesCommand
          oneWayDirection={oneWayDirection}
          isMobile={isMobile}
          imageLoaded={imageLoaded}
          handleImageLoad={handleImageLoad}
        />
      );
  }

  return <> {content}</>;
}

export default App;
