import { forwardRef } from "react";
import { FaMicrophone } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

import { ButtonProps } from "@/utils/types";

const getColor = ($clientCode: string) => {
  switch ($clientCode) {
    case "signage":
      return "var(--button-background)";
    case "rester":
    case "ceatec":
      return "#005EAC";
    default:
      return "";
  }
};

const Button = styled.button<{ $clientCode: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 420px;
  height: 420px;
  border: 18px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
  ${({ $clientCode }) => `background: ${getColor($clientCode)}`};

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

const SoundWavesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`;

const waveAnimation = keyframes`
  0%, 100% { transform: scaleY(1); }
  50% { transform: scaleY(2); }
`;

const StyledSoundWaves = styled.div<{ delay: number }>`
  border: 8px solid #fff;
  height: 105px;
  width: 18px;
  background-color: #fff;
  border-radius: 100px;
  animation: ${waveAnimation} 1s ease-in-out infinite;
  animation-delay: ${({ delay }) => delay}s;
  margin: 0 10px;
`;

const StyledMicIcon = styled(FaMicrophone)`
  color: #fff;
`;

export const BigDisplayMicroPhoneButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    isMicRecording: boolean;
    onTouchStart: () => void;
    clientCode: string;
  }
>(
  (
    {
      className,
      isMicRecording,
      disabled,
      onClick,
      onMouseDown,
      onMouseUp,
      onTouchStart,
      clientCode,
    },
    ref,
  ) => (
    <Button
      className={className}
      $clientCode={clientCode}
      type="button"
      disabled={disabled}
      ref={ref}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
    >
      {isMicRecording ? (
        <SoundWavesWrapper>
          <StyledSoundWaves delay={0.1} />
          <StyledSoundWaves delay={0.3} />
          <StyledSoundWaves delay={0.5} />
          <StyledSoundWaves delay={0.7} />
          <StyledSoundWaves delay={0.9} />
        </SoundWavesWrapper>
      ) : (
        <StyledMicIcon size={173} />
      )}
    </Button>
  ),
);
