import { useState } from "react";

import {
  AssistantMicWrapper,
  UserActionWrapper,
  EndSpeakingWording,
} from "@/commands/ForExhibition/exhibitionMicArea.style";
import { BigDisplayMicroPhoneButton } from "@/components/BigDisplayMicroPhoneButton";
import { MiiboHookTypes } from "@/hooks/useMiibo";
import { useVoiceInput } from "@/hooks/useVoiceInput";

type Props = {
  miiboHooks: MiiboHookTypes;
  clientCode: string;
};

export function ExhibitionMicArea({ miiboHooks, clientCode }: Props) {
  const [textInput, setTextInput] = useState<string>("");

  const voiceInputHooks = useVoiceInput({
    onResult: (text: string) => {
      setTextInput(text);
    },
    onStopRecording: (text: string) => {
      setTextInput(text);
      miiboHooks.sendChat(textInput);
    },
  });

  return (
    <AssistantMicWrapper>
      <UserActionWrapper>
        <BigDisplayMicroPhoneButton
          isMicRecording={voiceInputHooks.isMicRecording}
          onTouchStart={() => {
            if (voiceInputHooks.isMicRecording) {
              voiceInputHooks.stopRecording();
            } else {
              voiceInputHooks.startRecording();
            }
          }}
          clientCode={clientCode}
        />
        <EndSpeakingWording>
          {voiceInputHooks.isMicRecording &&
            "喋り終わったらマイクボタンを押してね！"}
        </EndSpeakingWording>
      </UserActionWrapper>
    </AssistantMicWrapper>
  );
}
