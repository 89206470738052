import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { ChatHistoryType } from "@/commands/Chat/schema";
import { ClientSchema } from "@/types/client";

// キャラクターが喋っているかどうかのアトム
export const isCharacterSpeakingAtom = atom<boolean>(false);

// 設定されている音声の言語
export const languageAtom = atom<string>("ja");

// 音声入力が許可されているかどうかのアトム
export const isVoiceInputAllowedAtom = atom<boolean>(false);

// 画面の中央の位置を表すアトム
export const centerPositionAtom = atom<{ x: number; y: number }>({
  x: 0,
  y: 0,
});

// レコキャラクターの動きのアトム
export const CharacterMotions = [
  "normal",
  "attention",
  "happy",
  "talk",
  "respectful",
  "greeting",
];
export const characterMotionAtom =
  atom<(typeof CharacterMotions)[number]>("normal");

export const clientConfigAtom = atom<ClientSchema>({
  id: "",
  clientCode: "",
  commands: [],
  name: "",
  character: {
    normal: "",
    attention: "",
    happy: "",
    talk: "",
    respectful: "",
    greeting: "",
  },
  excludedUrls: [],
  isSignage: false,
  isForExhibition: false,
  exhibitionBackground: undefined,
  isAssistantOnly: false,
  smallIcon: "",
  bottomHeightPC: 0,
  bottomHeight: 0,
  assistantBanner: undefined,
  bannerLink: undefined,
  logoImage: undefined,
});

export const characterSrcAtom = atom<string>("");

export type ParentSiteStateType = {
  outerWidth?: number;
  outerHeight?: number;
};

export const parenSiteStateAtom = atom<ParentSiteStateType>({});

export const customerCodeAtom = atom<string>("");

export const currentCommandAtom = atom<
  | "sales"
  | "chat"
  | "circle"
  | "assistant"
  | "close"
  | "chat_atoffice"
  | "chat_miibo"
  | "chat_atoffice_entrance"
>("sales");

export const chatHistoryAtom = atomWithStorage<
  Record<string, ChatHistoryType[]>
>("chatComments", {});

export const lastUpdatedChatCommentsAtom = atomWithStorage<
  Record<string, Date | null>
>("lastUpdatedChatComments", {});

export const chatIdAtom = atomWithStorage<Record<string, string>>("chatId", {});

export const parentUrlAtom = atom<string>("");
