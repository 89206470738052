import { useState } from "react";
import ReactMarkdown, { Components } from "react-markdown";

import {
  ChatAreaWrapper,
  CloseButton,
  ChatLogWrapper,
  ChatLogContents,
  ChatLogContent,
  AssistantCommentWrapper,
  AssistantComment,
  CommentLoading,
  UserSelectButtonsWrapper,
  UserSelectButton,
  UserActionWrapper,
} from "@/commands/Assistant/index.style";
import { ButtonMicroPhone } from "@/components/ButtonMicroPhone";
import { ButtonSend } from "@/components/ButtonSend";
import { Input } from "@/components/Input";
import { MiiboHookTypes } from "@/hooks/useMiibo";
import { useVoiceInput } from "@/hooks/useVoiceInput";

type Props = {
  isChatLogExpanded: boolean;
  setIsChatLogExpanded: (val: boolean) => void;
  miiboHooks: MiiboHookTypes;
  isMobile: boolean;
  hasAssistantBanner: boolean;
  hasBannerLink: boolean;
};

const LinkRenderer: Components["a"] = function LinkRenderer({
  href,
  children,
}) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const OlRenderer: Components["ol"] = function OlRenderer({ children }) {
  return <ol style={{ paddingLeft: "10px" }}>{children}</ol>;
};

export function AssistantChatArea({
  isChatLogExpanded,
  setIsChatLogExpanded,
  miiboHooks,
  isMobile,
  hasAssistantBanner,
  hasBannerLink,
}: Props) {
  const [textInput, setTextInput] = useState<string>("");

  const voiceInputHooks = useVoiceInput({
    onResult: (text: string) => {
      setTextInput(text);
    },
    onStopRecording: (text: string) => {
      setTextInput(text);
    },
  });

  const [isComposing, setIsComposing] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);

  return isChatLogExpanded ? (
    <ChatAreaWrapper>
      <CloseButton onClick={() => setIsChatLogExpanded(false)} />
      {miiboHooks.chatLog.map((chatLog) => (
        <ChatLogWrapper key={chatLog.content} role={chatLog.role}>
          <ChatLogContents role={chatLog.role}>
            <ChatLogContent role={chatLog.role}>
              {chatLog.content}
            </ChatLogContent>
          </ChatLogContents>
        </ChatLogWrapper>
      ))}
    </ChatAreaWrapper>
  ) : (
    <AssistantCommentWrapper
      isMobile={isMobile}
      hasAssistantBanner={hasAssistantBanner}
      hasBannerLink={hasBannerLink}
    >
      {!miiboHooks.isLoading && (
        <AssistantComment
          onClick={() => setIsChatLogExpanded(true)}
          onKeyDown={() => setIsChatLogExpanded(true)}
          tabIndex={0}
          role="button"
          style={{ cursor: "pointer" }}
        >
          <ReactMarkdown components={{ a: LinkRenderer, ol: OlRenderer }}>
            {miiboHooks.lastAssistantMessage}
          </ReactMarkdown>
        </AssistantComment>
      )}

      {miiboHooks.isLoading && (
        <AssistantComment>
          <CommentLoading />
        </AssistantComment>
      )}

      <UserSelectButtonsWrapper>
        {miiboHooks.chatOptions.map((option) => (
          <UserSelectButton
            key={option.value}
            type="button"
            isButtonVisible={isButtonVisible}
            onClick={() => {
              setIsButtonVisible(false);
              miiboHooks.sendChat(option.value);
            }}
          >
            {option.label}
          </UserSelectButton>
        ))}
      </UserSelectButtonsWrapper>
      <UserActionWrapper>
        <Input
          className="textInputWidth"
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          disabled={voiceInputHooks.isMicRecording}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isComposing) {
              if (e.currentTarget.value === "") {
                return;
              }
              miiboHooks.sendChat(textInput);
              setTextInput("");
            }
          }}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <ButtonSend
          isMicRecording={voiceInputHooks.isMicRecording}
          className="buttonSendMargin"
          onButtonDown={() => {
            miiboHooks.sendChat(textInput);
            setTextInput("");
          }}
          disabled={voiceInputHooks.isMicRecording}
        />
        <ButtonMicroPhone
          className="buttonMicroPhoneMargin"
          isMicRecording={voiceInputHooks.isMicRecording}
          onButtonDown={voiceInputHooks.startRecording}
          onButtonUp={voiceInputHooks.stopRecording}
        />
      </UserActionWrapper>
    </AssistantCommentWrapper>
  );
}
