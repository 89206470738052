import { AiOutlineClose } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const AssistantStartWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AssistantWrapper = styled.div<{ $isSignage: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) =>
    props.$isSignage ? "white" : "rgba(255, 255, 255, 0.6)"};
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const AssistantIconWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  & > div {
    // アイコンの位置を下に修正したい場合
    // position: absolute;
    // bottom: 0;

    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const AssistantCloseButton = styled(AiOutlineClose).withConfig({
  shouldForwardProp: (prop) => !["isChatLogExpanded"].includes(prop),
})<{ isChatLogExpanded: boolean }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  cursor: pointer;
  display: ${(props) => (props.isChatLogExpanded ? "none" : "block")};
`;

export const AssistantStartButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #005eac;
  border: 8px solid rgba(13, 13, 13, 1);
  color: #fff;
  border-radius: 100px;
  cursor: pointer;
  font-size: 68px !important;
  font-weight: 700;

  width: 600px;
  height: 200px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const AssistantEndButtonWrapper = styled.div`
  position: fixed;
  top: 160px;
  right: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;

export const AssistantEndButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #005eac;
  border: 8px solid rgba(13, 13, 13, 1);
  color: #fff;
  border-radius: 100px;
  cursor: pointer;
  font-size: 68px !important;
  font-weight: 700;

  width: 600px;
  height: 200px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const AssistantSwitchButtonWrapper = styled.div`
  position: fixed;
  top: 400px;
  right: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;

export const AssistantSwitchButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["clientCode"].includes(prop),
})<{ clientCode: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  border: 8px solid #3e3a3a;

  border-radius: 200px;
  cursor: pointer;
  font-size: 68px !important;
  font-weight: 700;
  width: 521px;
  height: 178px;

  &:active {
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);

    color: #fff;
    background-color: ${({ clientCode }) =>
      clientCode === "rester" ? "#C74C81" : "#005EAC"};
  }
`;

export const AssistantSwitchText = styled.span.withConfig({
  shouldForwardProp: (prop) => !["clientCode"].includes(prop),
})<{ clientCode: string }>`
  font-size: 68px !important;
  font-weight: 700;
  color: ${({ clientCode }) =>
    clientCode === "rester" ? "#C74C81" : "#005EAC"};

  &:active {
    color: #fff;
    background-color: ${({ clientCode }) =>
      clientCode === "rester" ? "#C74C81" : "#005EAC"};
  }
`;

export const AssistantSwitchIcon = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    !["clientCode", "isSwitchClicked"].includes(prop),
})<{ clientCode: string; isSwitchClicked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  width: 138px;
  height: 138px;
  border: 8px solid #3e3a3a;

  background-color: ${({ clientCode, isSwitchClicked }) => {
    if (isSwitchClicked) return "#fff";
    return clientCode === "rester" ? "#C74C81" : "#005EAC";
  }};
  border-radius: 50%;
`;

export const AssistantSwitchImage = styled.img``;
