import { useAtomValue } from "jotai";
import { useMemo, useState } from "react";

import { Character } from "@/commands/AssistantOnly/character";
import s from "@/commands/AssistantOnly/index.module.scss";
import { AssistantNormalMode } from "@/commands/AssistantOnly/normalMode";
import { FrameState } from "@/hooks/useFrameControl";
import ArrowSvg from "@/public/arrow.svg";
import CommentSvg from "@/public/comment.svg";
import { clientConfigAtom } from "@/utils/atoms";

type Props = {
  setFrameState: React.Dispatch<React.SetStateAction<FrameState | undefined>>;
  imageLoaded: boolean;
  handleImageLoaded: () => void;
  isMobile: boolean;
};

export default function AssistantOnly({
  setFrameState,
  imageLoaded,
  handleImageLoaded,
  isMobile,
}: Props) {
  const [showChat, setShowChat] = useState(false);
  const [isChatLogExpanded, setIsChatLogExpanded] = useState<boolean>(false);
  const client = useAtomValue(clientConfigAtom);
  const assistantBannerSrc = useMemo(
    () => client.assistantBanner || "",
    [client],
  );

  const handleShowChat = () => {
    setShowChat((prev) => !prev);

    if (isMobile) {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: "100%",
          height: "calc(100dvh - 20%)",
        },
      });
    } else {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: "50%",
          height: "calc(100dvh - 10%)",
          transform: "translateX(-50%)",
        },
      });
    }
  };

  const bannerLink = useMemo(() => client.bannerLink || "", [client]);

  return (
    <div
      className={[
        s.AssistantWrapper,
        isMobile ? s.isMobile : undefined,
        showChat ? s.showChat : undefined,
      ].join(" ")}
    >
      {!showChat && (
        <div className={s.StartButtonWrapper}>
          <button
            type="button"
            className={s.StartButton}
            onClick={handleShowChat}
          >
            <img className={s.CommentImage} src={CommentSvg} alt="" />
            <span>
              AIキャラと
              <br />
              会話を始める
            </span>
            <img className={s.CommentArrow} src={ArrowSvg} alt="" />
          </button>
        </div>
      )}

      <div
        className={[
          s.AssistantIconWrapper,
          isMobile ? s.isMobile : undefined,
          showChat ? s.showChat : undefined,
          assistantBannerSrc ? s.hasAssistantBanner : undefined,
        ].join(" ")}
      >
        <Character
          handleImageLoad={handleImageLoaded}
          imageLoaded={imageLoaded}
        />
      </div>

      {showChat && (
        <AssistantNormalMode
          setIsChatLogExpanded={setIsChatLogExpanded}
          isChatLogExpanded={isChatLogExpanded}
          clientId={client.id}
          isMobile={isMobile}
          hasAssistantBanner={Boolean(assistantBannerSrc)}
          hasBannerLink={Boolean(bannerLink)}
        />
      )}
    </div>
  );
}
