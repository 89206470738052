import { useRef, useEffect, useCallback } from "react";
import { GrPowerReset } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";
import { RiCalendarScheduleLine } from "react-icons/ri";

import ChatProcessing from "@/commands/ChatAtoffice/components/ChatProcessing";
import s from "@/commands/ChatAtoffice/components/ChatTemplate/ChatTemplate.module.scss";
import MessageItem from "@/commands/ChatAtoffice/components/MessageItem";
// import {
//   dummyRecommendMessage,
//   dummyScheduleMessage,
// } from "@/commands/ChatAtoffice/sample.data";
import { type ChatResponse } from "@/commands/ChatAtoffice/types";
import { ClientSchema } from "@/types/client";

type Props = {
  client: ClientSchema;
  handleCloseBtn: () => void;
  isWaiting: boolean;
  chatHistory: ChatResponse[];
  sendUserMessage: (userMessage: string) => void;
  resetChat: () => void;
};
function ChatTemplate({
  client,
  handleCloseBtn,
  isWaiting,
  chatHistory,
  sendUserMessage,
  resetChat,
}: Props) {
  const mainRef = useRef<HTMLElement>(null);

  const scrollToBottom = useCallback(() => {
    if (mainRef.current) {
      mainRef.current.scrollTop = mainRef.current.scrollHeight;
    }
  }, [mainRef]);
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, chatHistory, isWaiting]);

  return (
    <div className={s.ChatTemplate}>
      <header>
        <button
          className={[s._back_icon, s._tooltip, "tooltip tooltip-bottom"].join(
            " ",
          )}
          data-tip="戻る"
          type="button"
          aria-label="Close"
          onClick={handleCloseBtn}
        >
          <IoIosArrowBack />
        </button>
        <div className={s._reco_info}>
          <div className={s._profile_wrapper}>
            <img src={client.character.attention} alt={client.name} />
          </div>
          <div className={s._displayname_wrapper}>
            <h2>at office</h2>
            <div className={s._status}>
              <span />
              online
            </div>
          </div>
        </div>
        <div className={s._header_actions}>
          <button
            className={[
              s._icon_btn,
              s._reset,
              s._tooltip,
              "tooltip tooltip-bottom",
            ].join(" ")}
            type="button"
            aria-label="reset"
            onClick={resetChat}
            data-tip="チャットをリセット"
          >
            <GrPowerReset />
          </button>
          <button
            className={[
              s._icon_btn,
              s._reservation,
              s._tooltip,
              "tooltip tooltip-bottom",
            ].join(" ")}
            type="button"
            aria-label="reservation"
            onClick={() => sendUserMessage("いますぐ来店予約")}
            data-tip="来店予約"
          >
            <RiCalendarScheduleLine />
          </button>
        </div>
      </header>
      <main ref={mainRef}>
        {/* FIXME - remove front dummy */}
        {/* <MessageItem
          item={dummyRecommendMessage}
          client={client}
          sendUserMessage={sendUserMessage}
        /> */}
        {/* <MessageItem
          item={dummyScheduleMessage}
          client={client}
          sendUserMessage={sendUserMessage}
          scrollToBottom={scrollToBottom}
        /> */}
        {chatHistory.map((item) => (
          <MessageItem
            // FIXME need unique id for key
            key={`m-${item.timestamp}-${item.chat_text}`}
            item={item}
            client={client}
            sendUserMessage={sendUserMessage}
            scrollToBottom={scrollToBottom}
          />
        ))}
        {(isWaiting || chatHistory.length === 0 ) && (
          <ChatProcessing client={client} />
        )}
      </main>
    </div>
  );
}

export default ChatTemplate;
