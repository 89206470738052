import { useCallback, useEffect, useRef } from "react";
import { GrPowerReset } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";

import ChatProcessing from "@/commands/ChatMiibo/components/ChatProcessing";
import s from "@/commands/ChatMiibo/components/ChatTemplate/ChatTemplate.module.scss";
import MessageItem from "@/commands/ChatMiibo/components/MessageItem";
import { ClientSchema } from "@/types/client";
import { type ChatMessage } from "@/types/miibo.schema";

type Props = {
  client: ClientSchema;
  handleCloseBtn: () => void;
  chatLog: ChatMessage[];
  reset: () => void;
  sendChat: (text: string) => void;
  isLoading: boolean;
  options: string[];
};
function ChatTemplate({
  client,
  handleCloseBtn,
  chatLog,
  reset,
  sendChat,
  isLoading,
  options,
}: Props) {
  // scroll to bottom
  const mainRef = useRef<HTMLElement>(null);

  const scrollToBottom = useCallback(() => {
    if (mainRef.current) {
      mainRef.current.scrollTop = mainRef.current.scrollHeight;
    }
  }, [mainRef]);
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, isLoading, chatLog]);

  // send user message
  const sendUserMessage = useCallback(
    (userMessage: string) => {
      sendChat(userMessage);
    },
    [sendChat],
  );

  return (
    <div className={s.ChatTemplate}>
      <header>
        <button
          className={[s._back_icon, s._tooltip, "tooltip tooltip-bottom"].join(
            " ",
          )}
          data-tip="戻る"
          type="button"
          aria-label="Close"
          onClick={handleCloseBtn}
        >
          <IoIosArrowBack />
        </button>
        <div className={s._reco_info}>
          <div className={s._profile_wrapper}>
            <img src={client.character.attention} alt={client.name} />
          </div>
          <div className={s._displayname_wrapper}>
            <h2>at office</h2>
            <div className={s._status}>
              <span />
              online
            </div>
          </div>
        </div>
        <div className={s._header_actions}>
          <button
            className={[
              s._icon_btn,
              s._reset,
              s._tooltip,
              "tooltip tooltip-bottom",
            ].join(" ")}
            type="button"
            aria-label="reset"
            onClick={reset}
            data-tip="リセット"
          >
            <GrPowerReset />
          </button>
        </div>
      </header>
      <main ref={mainRef}>
        {chatLog.map((item, index) => (
          <MessageItem
            key={`miibo-${item.content}`}
            item={item}
            client={client}
            sendUserMessage={sendUserMessage}
            scrollToBottom={scrollToBottom}
            tags={index === 0 ? options : []}
          />
        ))}
        {(chatLog.length === 0 || isLoading) && (
          <ChatProcessing client={client} />
        )}
      </main>
    </div>
  );
}

export default ChatTemplate;
