import { format } from "date-fns";

import { type ChatResponse } from "@/commands/ChatAtoffice/types";

const positionTags = [
  "山手線上",
  "京浜東北線上",
  "中央線上",
  "総武線上",
  "東海道線上",
  "横須賀線上",
  "京葉線上",
  "埼京線上",
  "南武線上",
  "青梅線上",
  "五日市線上",
  "中央本線上",
  "常磐線上",
  "京王線上",
  "小田急線上",
  "東横線上",
  "田園都市線上",
  "大井町線上",
  "目黒線上",
  "池袋線上",
  "新宿線上",
  "多摩湖線上",
  "有楽町線上",
  "山口線上",
  "銀座線上",
  "丸ノ内線上",
  "日比谷線上",
  "東西線上",
  "千代田線上",
  "有楽町線上",
  "半蔵門線上",
  "南北線上",
  "副都心線上",
  "浅草線上",
  "三田線上",
  "新宿線上",
  "大江戸線上",
  "ゆりかもめ上",
  "りんかい線上",
  "多摩モノレール上",
];
const wardTags = [
  "千代田区",
  "中央区",
  "港区",
  "新宿区",
  "文京区",
  "台東区",
  "墨田区",
  "江東区",
  "品川区",
  "目黒区",
  "大田区",
  "世田谷区",
  "渋谷区",
  "中野区",
  "杉並区",
  "豊島区",
  "北区",
  "荒川区",
  "板橋区",
  "練馬区",
  "足立区",
  "葛飾区",
  "江戸川区",
];
function getRandomWard(arr: string[]) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export function setInitialChat(): ChatResponse {
  return {
    chat_text: "借りたい場所はお決まりでしょうか?",
    suggest_tags: [
      getRandomWard(positionTags),
      getRandomWard(wardTags),
      "特になし",
    ],
    recommends: [],
    chat_history: [],
    timestamp: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    suggest_schedules: [],
    suggest_schedules_flg: false,
  };
}

export const CHARACTER_RECRUIT_URL =
  "https://character-recruit.app/atoffice/register";

export const dummyRecommendMessage: ChatResponse = {
  chat_text:
    "「足立区」でおすすめの物件はこちらです。その他の条件についてお聞かせいただけますか？",
  suggest_tags: ["賃料45万円以下", "駅徒歩5分以下", "41坪以上"],
  suggest_schedules: [],
  suggest_schedules_flg: false,
  recommends: [
    {
      id: "42831_3",
      building_name: "大樹生命北千住ビル",
      address: "足立区千住 仲町41-1",
      nearest_stations: [
        "東京地下鉄千代田線北千住駅 徒歩4分",
        "京成電鉄本線千住大橋駅 徒歩10分",
        "常磐新線北千住駅 徒歩4分",
        "常磐線北千住駅 徒歩2分",
        "常磐緩行線北千住駅 徒歩2分",
        "日比谷線北千住駅 徒歩4分",
        "伊勢崎線北千住駅 徒歩4分",
      ],
      rent_fee_total: null,
      floor: "3階",
      floor_size_tsubo: 38.34,
      floor_size_meter: 126.77,
      room_url: "https://www.at-office.jp/detail/42831/3/",
      image_url:
        "https://img.multi-use.net/adm1/chintai/atg/images/building/J00100388685_1.jpg",
      recommend_text: "駅徒歩2分の好立地、広々オフィスです。",
    },
    {
      id: "31342_1A",
      building_name: "ラ・プレーヌＧ．Ｓ．ビル",
      address: "足立区弘道１丁目2-20",
      nearest_stations: [
        "伊勢崎線五反野駅 徒歩2分",
        "東武伊勢崎・大師線五反野駅 徒歩1分",
        "伊勢崎線小菅駅 徒歩11分",
        "常磐新線青井駅 徒歩14分",
      ],
      rent_fee_total: null,
      floor: "1階",
      floor_size_tsubo: 130.91,
      floor_size_meter: 432.76,
      room_url: "https://www.at-office.jp/detail/31342/1A/",
      image_url: "",
      recommend_text: "五反野駅徒歩1分の便利なオフィス。",
    },
    {
      id: "200133_1A",
      building_name: "第１矢野新ビル",
      address: "足立区梅島３丁目32-24",
      nearest_stations: [
        "東武大師線西新井駅 徒歩1分",
        "伊勢崎線梅島駅 徒歩8分",
        "東武大師線大師前駅 徒歩12分",
      ],
      rent_fee_total: 816300,
      floor: "1階",
      floor_size_tsubo: 54.42,
      floor_size_meter: 179.9,
      room_url: "https://www.at-office.jp/detail/200133/1A/",
      image_url:
        "https://img.multi-use.net/adm1/chintai/atg/images/building/J00100464525_1.jpg",
      recommend_text: "西新井駅徒歩1分、即入居可能な物件です。",
    },
    {
      id: "33575_2B",
      building_name: "Ｊプロ千住ビル",
      address: "足立区千住 中居町28-5",
      nearest_stations: [
        "常磐線北千住駅 徒歩8分",
        "常磐緩行線北千住駅 徒歩7分",
        "東京地下鉄千代田線北千住駅 徒歩8分",
      ],
      rent_fee_total: null,
      floor: "2階",
      floor_size_tsubo: 41.32,
      floor_size_meter: 136.62,
      room_url: "https://www.at-office.jp/detail/33575/2B/",
      image_url:
        "https://img.multi-use.net/adm1/chintai/atg/images/building/J00100381112_1.jpg",
      recommend_text: "北千住駅徒歩7分、広々オフィススペース。",
    },
    {
      id: "208093_4A",
      building_name: "仮）江北メディカルスクエアビル",
      address: "足立区西新井本町２丁目29-1",
      nearest_stations: [
        "日暮里舎人ライナー江北駅 徒歩5分",
        "日暮里・舎人線西新井大師西駅 徒歩6分",
        "日暮里・舎人線高野駅 徒歩12分",
        "東武大師線大師前駅 徒歩13分",
        "日暮里・舎人線谷在家駅 徒歩16分",
        "日暮里・舎人線扇大橋駅 徒歩18分",
      ],
      rent_fee_total: 540020,
      floor: "4階",
      floor_size_tsubo: 41.54,
      floor_size_meter: 137.32,
      room_url: "https://www.at-office.jp/detail/208093/4A/",
      image_url:
        "https://img.multi-use.net/adm1/chintai/atg/images/building/J00100513454_1.jpg",
      recommend_text: "江北駅徒歩5分の新築物件です。",
    },
  ],
  chat_history: [
    {
      role: "user",
      content: "足立区で探しています",
    },
    {
      role: "assistant",
      content:
        "「足立区」でおすすめの物件はこちらです。その他の条件についてお聞かせいただけますか？",
    },
  ],
  timestamp: "2024-08-24 19:37:39",
};

export const dummyScheduleMessage: ChatResponse = {
  chat_text: "以下の日程で担当者からご案内できます。",
  suggest_tags: [],
  suggest_schedules_flg: true,
  suggest_schedules: [
    "2024-08-26T09:00:00",
    "2024-08-26T10:00:00",
    "2024-08-26T11:00:00",
    "2024-08-26T12:00:00",
    "2024-08-26T13:00:00",
    "2024-08-26T14:00:00",
    "2024-08-26T15:00:00",
    "2024-08-26T16:00:00",
    "2024-08-26T17:00:00",
    "2024-08-27T09:00:00",
    "2024-08-27T10:00:00",
    "2024-08-27T11:00:00",
    "2024-08-27T12:00:00",
    "2024-08-27T13:00:00",
    "2024-08-27T14:00:00",
    "2024-08-27T15:00:00",
    "2024-08-27T16:00:00",
    "2024-08-27T17:00:00",
    "2024-08-28T09:00:00",
    "2024-08-28T10:00:00",
    "2024-08-28T11:00:00",
    "2024-08-28T12:00:00",
    "2024-08-28T13:00:00",
    "2024-08-28T14:00:00",
    "2024-08-28T15:00:00",
    "2024-08-28T16:00:00",
    "2024-08-28T17:00:00",
    "2024-08-29T09:00:00",
    "2024-08-29T10:00:00",
    "2024-08-29T11:00:00",
    "2024-08-29T12:00:00",
    "2024-08-29T13:00:00",
    "2024-08-29T14:00:00",
    "2024-08-29T15:00:00",
    "2024-08-29T16:00:00",
    "2024-08-29T17:00:00",
    "2024-08-30T09:00:00",
    "2024-08-30T10:00:00",
    "2024-08-30T11:00:00",
    "2024-08-30T12:00:00",
    "2024-08-30T13:00:00",
    "2024-08-30T14:00:00",
    "2024-08-30T15:00:00",
    "2024-08-30T16:00:00",
    "2024-08-30T17:00:00",
  ],
  recommends: [],
  chat_history: [
    {
      role: "user",
      content: "荒川区で探しています",
    },
    {
      role: "assistant",
      content: "「荒川区」でおすすめの物件はこちらです。",
    },
    {
      role: "user",
      content: "今月中に借りたいです",
    },
    {
      role: "assistant",
      content: "以下の日程で担当者からご案内できます。",
    },
  ],
  timestamp: "2024-08-24 20:23:25",
};
