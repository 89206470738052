


export const charactersOfGoogleEnglishTts = [
  "en-GB-Standard-A",
  "en-GB-Standard-B",
  "en-GB-Standard-C",
  "en-GB-Standard-D",
  "en-GB-Standard-F",
  "en-US-Polyglot-1",
  "en-US-Standard-A",
  "en-US-Standard-B",
  "en-US-Standard-C",
  "en-US-Standard-D",
  "en-US-Standard-E",
  "en-US-Standard-F",
  "en-US-Standard-G",
  "en-US-Standard-H",
  "en-US-Standard-I",
  "en-US-Standard-J",
];

