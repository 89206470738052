"use client";

// import { format, parse } from "date-fns";
import { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";

import s from "@/commands/ChatMiibo/components/MessageItem/MessageItem.module.scss";
import { ClientSchema } from "@/types/client";

interface Props {
  item: { content: string; role: string };
  client: ClientSchema;
  sendUserMessage: (userMessage: string) => void;
  scrollToBottom: () => void;
  tags: string[];
}
function MessageItem({
  item,
  client,
  sendUserMessage,
  scrollToBottom,
  tags,
}: Props) {
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  function handleTag(tag: string) {
    setSelectedTag(tag);
    sendUserMessage(`${tag}`);
    scrollToBottom();
  }

  // mtsm-todo miiboもタイムスタンプいれるかも
  // timestamp
  // const timestamp = item.timestamp
  //   ? parse(item.timestamp, "yyyy-MM-dd HH:mm:ss", new Date())
  //   : new Date();
  // const formattedTimestamp = format(timestamp, "HH:mm");

  return (
    <div className={s.MessageItem}>
      {/* user message */}
      {item.role === "user" && (
        <div className={[s._row_chat_text, s._user].join(" ")}>
          <div className={s._chat_text}>
            <p>
              {item.content}
              {/* <span className={s._timestamp}>{formattedTimestamp}</span> */}
            </p>
          </div>
        </div>
      )}

      {/* reco message */}
      {item.role === "assistant" && (
        <div className={[s._row_chat_text, s._reco].join(" ")}>
          <div className={s._profile_wrapper}>
            <img src={client.character.attention} alt={client.name} />
          </div>
          <div className={s._chat_text}>
            <div className={s._display_name}>{client.name}</div>
            <p>
              {item.content}
              {/* <span className={s._timestamp}>{formattedTimestamp}</span> */}
            </p>
          </div>
        </div>
      )}

      {/* suggest tags */}
      {tags.length > 0 && (
        <div className={s._row_suggest_tags}>
          <p>次のような質問もできます</p>
          <div className={s._tags_scroll_area}>
            {tags.map((tag, index) => (
              <div
                // FIXME need unique id for key
                key={`tag-${tag}`}
                className={[
                  s._tag,
                  selectedTag === tag ? s._active : undefined,
                ].join(" ")}
                onClick={() => handleTag(tag)}
                role="button"
                tabIndex={index}
              >
                <p>{tag}</p>
                {selectedTag === tag ? <FaCheck /> : <IoIosArrowForward />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default MessageItem;
