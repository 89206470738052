import { useState } from "react";
import { BiSolidArea } from "react-icons/bi";
import { FaLocationDot, FaLayerGroup } from "react-icons/fa6";
import { RiImageFill } from "react-icons/ri";

import s from "@/commands/ChatAtoffice/components/RecommendItem/RecommendItem.module.scss";
import { type Recommend } from "@/commands/ChatAtoffice/types";

function NearestStations({ stations }: { stations: string[] }) {
  if (stations.length <= 3)
    return (
      <>
        {stations.map((station) => (
          // FIXME need unique id for key
          <p className={s._meta} key={`station-${station}`}>
            <FaLocationDot />
            {station}
          </p>
        ))}
      </>
    );

  const nearestThree = stations.slice(0, 3);
  return (
    <>
      {nearestThree.map((station) => (
        // FIXME need unique id for key
        <p className={s._meta} key={`station-${station}`}>
          <FaLocationDot />
          {station}
        </p>
      ))}
      <p className={[s._meta, s._lightgrey].join(" ")}>
        <FaLocationDot style={{ opacity: 0 }} />他{stations.length - 3}駅
      </p>
    </>
  );
}

interface Props {
  item: Recommend;
  sendUserMessage: (userMessage: string) => void;
}
function RecommendItem({ item, sendUserMessage }: Props) {
  const [imgError, setImgError] = useState(false);
  return (
    <div className={s.RecommendItem}>
      <div className={s._img_wrapper}>
        {imgError ? (
          <RiImageFill />
        ) : (
          <img
            src={item.image_url}
            alt={item.building_name}
            onError={() => setImgError(true)}
          />
        )}
      </div>
      <div className={s._info_wrapper}>
        <h3>{item.building_name}</h3>
        <h4>
          {item.rent_fee_total !== null ? (
            <>
              <span>賃料</span>
              {item.rent_fee_total.toLocaleString()}
              <span>円</span>
            </>
          ) : (
            <span>賃料はお問合せ下さい</span>
          )}
        </h4>
        <p className={[s._meta, s._area].join(" ")}>
          <BiSolidArea />
          <span>{item.floor_size_tsubo}</span>坪 ({item.floor_size_meter}㎡)
        </p>
        <NearestStations stations={item.nearest_stations} />
        {item.floor && (
          <p className={s._meta}>
            <FaLayerGroup />
            {item.floor}
          </p>
        )}
        <p className={s._detail}>{item.recommend_text}</p>
      </div>
      <div className={s._btns_wrapper}>
        <a
          href="https://www.at-office.jp/property-inquiry-page/"
          target="_blank"
          rel="noreferrer"
        >
          お問い合わせ
        </a>
        {/* https://www.at-office.jp/visit/ */}
        <div
          className={s._btn_reserve}
          onClick={() =>
            sendUserMessage(`「${item.building_name}」で来店予約します`)
          }
        >
          今すぐ来店予約
        </div>
      </div>
      <a
        href={item.room_url}
        target="_blank"
        className={s._footer}
        rel="noreferrer"
      >
        詳細を見る
      </a>
    </div>
  );
}

export default RecommendItem;
