import s from "@/commands/ChatMiibo/components/ChatProcessing/ChatProcessing.module.scss";
import { ClientSchema } from "@/types/client";

interface Props {
  client: ClientSchema;
}
function ChatProcessing({ client }: Props) {
  return (
    <div className={s.ChatProcessing}>
      <div className={s._profile_wrapper}>
        <img src={client.character.attention} alt={client.name} />
      </div>
      <div className={s._chat_text}>
        <div className={s._display_name}>{client.name}</div>
        <div className={s.dots_wrapper}>
          <div className={s.dots} />
        </div>
      </div>
    </div>
  );
}

export default ChatProcessing;
