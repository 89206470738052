import styled, { css, keyframes } from "styled-components";

import LineIconPath from "@/assets/images/line_circle.png";

const moveAnimation = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
) => keyframes`
  from {
    top: ${startY}px;
    left: ${startX}px;
  }
  to {
    top: ${endY}px;
    left: ${endX}px;
  }
`;

export const SalesButtonsContainer = styled.div`
  z-index: 10;
  position: absolute;
  top: -30px;
  left: -30px;
  width: 200px;
  height: 200px;
  background: transparent;
`;

export const MenuItem = styled.li.withConfig({
  shouldForwardProp: (prop) =>
    !["x", "y", "centerX", "centerY", "isMobile"].includes(prop),
})<{
  x: number;
  y: number;
  centerX: number;
  centerY: number;
  isMobile: boolean;
}>`
  z-index: 2;
  position: absolute !important;
  display: flex;
  justify-content: center;
  ${({ x, y }) => css`
    left: ${x}px;
    top: ${y}px;
  `}

  ${({ isMobile }) =>
    css`
      width: ${isMobile ? "50px" : "90px"};
    `}
  height: 90px;

  padding: 0 !important;
  line-height: 5px;
  border-radius: 50%;
  cursor: pointer;
  animation: ${({ centerX, centerY, x, y }) =>
      moveAnimation(centerX, centerY, x, y)}
    0.3s ease-in-out;
`;

const commonButtonStyles = css`
  color: #fff;
  position: relative;
  text-decoration: none;
  z-index: 10;
  background: var(--button-background);
  border-radius: 50%;
  width: 50px; /* アイコンの幅 */
  height: 50px; /* アイコンの高さ */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg,
  & > img {
    width: 25px;
    height: 25px;
  }
`;

export const MenuButtonATag = styled.a`
  ${commonButtonStyles}
`;

export const MenuButton = styled.button`
  ${commonButtonStyles}
  border: none;
`;

export const CircleButtonAssistant = styled.button.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{
  isMobile: boolean;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  background: var(--button-background);
  color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
  transition: background 0.3s ease;
  cursor: pointer;

  bottom: 90px;
  right: 35px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  ${(props) =>
    props.isMobile &&
    `
    bottom: 5px;
    right: 70px;
  `}
`;

export const MenuButtonTitle = styled.span.withConfig({
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})<{ isMobile: boolean }>`
  color: #16566f;
  background: #fff;
  border-radius: 10px;
  padding: 1px 2px;
  min-width: 50px;
  display: inline-block;
  font-size: 12px !important;
  z-index: 9999;
  text-align: center;
  position: absolute;
  bottom: 30px;
  box-shadow: 0px 0px 14px 0px #292c3230;
  white-space: nowrap;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 9px !important;
      min-width: 40px;
    `}
`;

export const SalesButtonLineIcon = styled.img.attrs({
  src: LineIconPath,
  alt: "line",
})`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
`;
