import { forwardRef } from "react";
import styled from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !["buttonType"].includes(prop),
})<{
  buttonType: "cancel" | "reset";
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  width: 151px;
  height: 45px;
  cursor: pointer;

  background-color: ${({ buttonType }) =>
    buttonType === "cancel" ? "fff" : "rgba(55, 112, 255, 1)"};
  border: ${({ buttonType }) =>
    buttonType === "cancel" ? "1px solid rgba(162, 162, 162, 1)" : "none"};

  @media (hover: hover) {
    &:hover {
      background: ${({ buttonType }) =>
        buttonType === "cancel"
          ? "rgba(0, 0, 0, 0.05)"
          : "rgba(55, 112, 255, 1)"};
    }
  }

  @media (hover: none) {
    &:active {
      background: ${({ buttonType }) =>
        buttonType === "cancel"
          ? "rgba(0, 0, 0, 0.05)"
          : "rgba(55, 112, 255, 1)"};
    }
  }
`;

export const ButtonModal = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    buttonType: "cancel" | "reset";
  }
>(({ className, onClick, buttonType }, ref) => (
  <Button
    className={className}
    type="button"
    buttonType={buttonType}
    ref={ref}
    onClick={onClick}
  >
    {buttonType === "cancel" ? "キャンセル" : "やり直す"}
  </Button>
));
