import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import { format } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

import s from "@/commands/ChatAtoffice/ChatAtoffice.module.scss";
import ChatAtofficeEntrance from "@/commands/ChatAtoffice/components/ChatAtofficeEntrance";
import { InputArea } from "@/commands/ChatAtoffice/components/ChatInput";
import ChatTemplate from "@/commands/ChatAtoffice/components/ChatTemplate";
import { useAreaHeight } from "@/commands/ChatAtoffice/hooks/useAreaHeight";
import useAtofficeRecommend from "@/commands/ChatAtoffice/hooks/useAtofficeRecommend";
import { setInitialChat } from "@/commands/ChatAtoffice/sample.data";
import { ChatResponse } from "@/commands/ChatAtoffice/types";
import { useVoiceInput } from "@/hooks/useVoiceInput";
import { clientConfigAtom, currentCommandAtom } from "@/utils/atoms";

type Props = {
  isMobile: boolean;
};

export default function ChatAtoffice({
  isMobile,
}: Props): JSX.Element {
  const clientConfig = useAtomValue(clientConfigAtom);
  const setCurrentCommand = useSetAtom(currentCommandAtom);

  const [textInput, setTextInput] = useState<string>("");

  const areaHeightHook = useAreaHeight();

  const voiceInputHook = useVoiceInput({
    onResult: (text: string) => {
      setTextInput(text);
    },
    onStopRecording: (text: string) => {
      setTextInput(text);
    },
  });

  const { postMessage } = useAtofficeRecommend();
  const [chatHistory, setChatHistory] = useState<ChatResponse[]>([]);
  const [isWaiting, setIswaiting] = useState(false);

  const resetChat = () => {
    setChatHistory([]);
  };
  useEffect(() => {
    if (chatHistory.length !== 0) return;
    setTimeout(() => {
      setChatHistory([setInitialChat()]);
    }, 300);
  }, [chatHistory]);

  const sendUserMessage = useCallback(
    async (userMessage: string) => {
      // delay loading ui
      setTimeout(() => {
        setIswaiting(true);
      }, 100);

      // push user message to chat_history
      setChatHistory((prev) => {
        const newItem: ChatResponse = {
          chat_text: null,
          suggest_tags: [],
          recommends: [],
          chat_history: [{ role: "user", content: userMessage }],
          timestamp: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          suggest_schedules: [],
          suggest_schedules_flg: false,
        };
        return [...prev, newItem];
      });

      // post message
      const reqBody = {
        chat_text: userMessage,
        chat_history: chatHistory[chatHistory.length - 1].chat_history,
      };
      const res = await postMessage(reqBody);
      setChatHistory((prev) => [...prev, res]);

      setIswaiting(false);
    },
    [chatHistory, postMessage],
  );

  const handleOnSend = useCallback(() => {
    setTextInput("");
    sendUserMessage(textInput);
  }, [sendUserMessage, textInput]);

  const handleCloseBtn = () => {
    setCurrentCommand("chat_atoffice_entrance");
  };
  return (
    <div
      className={[s.ChatAtoffice, isMobile ? s.isMobile : undefined].join(" ")}
    >
      <div
        className={[s.ChatAreaWrapper].join(" ")}
        style={{
          height: isMobile
            ? `${areaHeightHook.inputAreaHeight}px`
            : `${areaHeightHook.chatAreaHeight}px`,
        }}
      >
        <ChatTemplate
          client={clientConfig}
          handleCloseBtn={handleCloseBtn}
          isWaiting={isWaiting}
          chatHistory={chatHistory}
          sendUserMessage={sendUserMessage}
          resetChat={resetChat}
        />
      </div>
      <InputArea
        ref={areaHeightHook.inputAreaRef}
        handleOnSend={handleOnSend}
        isMicRecording={voiceInputHook.isMicRecording}
        disabled={isWaiting}
        text={textInput}
        textOnChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setTextInput(e.target.value)
        }
        startRecording={voiceInputHook.startRecording}
        stopRecording={voiceInputHook.stopRecording}
        isMobile={isMobile}
      />
    </div>
  );
}

export { ChatAtofficeEntrance };
