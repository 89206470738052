import { useSetAtom } from "jotai";
import { useMemo } from "react";
import { AiOutlineInstagram, AiOutlineQuestionCircle } from "react-icons/ai";
import { FaTiktok, FaFacebookF } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { RiTwitterXLine } from "react-icons/ri";
import { SiGoogleassistant } from "react-icons/si";

import {
  MenuButton,
  MenuButtonATag,
  MenuButtonTitle,
  MenuItem,
  SalesButtonLineIcon,
  SalesButtonsContainer,
} from "@/commands/Circle/components/SalesButtons/index.style";
import { ClientSchema } from "@/types/client";
import { currentCommandAtom } from "@/utils/atoms";

export type IconButtonLink = {
  id: string;
  element: JSX.Element;
  linkUrl?: string;
  text?: string;
  onClick?: () => void;
};

type Props = {
  isMobile: boolean;
  client: ClientSchema;
};
// 同心円配置を行う関数
function getCirclePositions(
  centerX: number,
  centerY: number,
  radius: number,
  elementCount: number,
  degrees: number,
  startAngle: number,
): { x: number; y: number }[] {
  const angleIncrement = (degrees * Math.PI) / 180 / elementCount;

  const positions = [];
  for (let i = 0; i < elementCount; i++) {
    const angle = startAngle + i * angleIncrement;
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);
    positions.push({ x, y });
  }

  return positions;
}
// 中心座標 (300, 300)、半径 200、要素の数 8、開き方 180度、開始角度 45度 の場合
// getCirclePositions(300, 300, 200, 8, 180, 45);

export function SalesButtons({ isMobile, client }: Props) {
  const setCurrentCommand = useSetAtom(currentCommandAtom);
  const iconButtonLinks = useMemo<IconButtonLink[]>(() => {
    const buttonLinks: IconButtonLink[] = [];
    if (client.commands.includes("assistant")) {
      buttonLinks.push({
        id: "assistant",
        element: <SiGoogleassistant />,
        text: client.clientCode === "rms" ? "チャットで聞く" : "Assistant",
        onClick: () => {
          setCurrentCommand("assistant");
        },
      });
    }
    if (client.commands.includes("chat")) {
      buttonLinks.push({
        id: "chat",
        element: <IoChatbubblesOutline />,
        text: "Chat",
        onClick: () => {
          setCurrentCommand("chat");
        },
      });
    }
    if (client.line) {
      buttonLinks.push({
        id: "line",
        element: <SalesButtonLineIcon />,
        linkUrl: client.line,
        text: "LINE",
      });
    }

    if (client.facebook) {
      buttonLinks.push({
        id: "facebook",
        element: <FaFacebookF />,
        linkUrl: client.facebook,
        text: "Facebook",
      });
    }

    if (client.instagram) {
      buttonLinks.push({
        id: "instagram",
        element: <AiOutlineInstagram />,
        linkUrl: client.instagram,
        text: "Instagram",
      });
    }
    if (client.xTwitter) {
      buttonLinks.push({
        id: "x",
        element: <RiTwitterXLine />,
        linkUrl: client.xTwitter,
        text: "xTwitter",
      });
    }

    if (client.tiktok) {
      buttonLinks.push({
        id: "tiktok",
        element: <FaTiktok />,
        linkUrl: client.tiktok,
        text: "TikTok",
      });
    }

    if (client.inquiryUrl) {
      buttonLinks.push({
        id: "inquiry",
        element: <FiMail />,
        linkUrl: client.inquiryUrl,
        text: "お問い合わせ",
      });
    }

    if (client.faqUrl) {
      buttonLinks.push({
        id: "faq",
        element: <AiOutlineQuestionCircle />,
        linkUrl: client.faqUrl,
        text: "FAQ",
      });
    }

    return buttonLinks.slice(0, 5);
  }, [
    client.commands,
    client.facebook,
    client.faqUrl,
    client.inquiryUrl,
    client.instagram,
    client.line,
    client.tiktok,
    client.xTwitter,
    setCurrentCommand,
  ]);

  const centerX = 100;
  const centerY = 100;
  const radius = isMobile ? 150 : 180;
  const positions = useMemo(
    () =>
      getCirclePositions(
        centerX,
        centerY,
        radius,
        iconButtonLinks.length,
        140,
        160,
      ),
    [iconButtonLinks.length, radius],
  );

  return (
    <SalesButtonsContainer>
      {iconButtonLinks.map((button, index) => (
        <MenuItem
          key={button.id}
          isMobile={isMobile}
          x={positions[index].x}
          y={positions[index].y}
          centerX={centerX}
          centerY={centerY}
          onClick={button.onClick}
        >
          {button.onClick ? (
            <MenuButton key={button.id} onClick={button.onClick}>
              {button.element}
            </MenuButton>
          ) : (
            <MenuButtonATag
              key={button.id}
              href={button.linkUrl}
              target="_blank"
            >
              {button.element}
            </MenuButtonATag>
          )}
          <MenuButtonTitle isMobile={isMobile}>{button.text}</MenuButtonTitle>
        </MenuItem>
      ))}
    </SalesButtonsContainer>
  );
}
