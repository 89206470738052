import styled from "styled-components";

export const ButtonFlat = styled.button`
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
  overflow: hidden;
  color: #fff;
  border-radius: 0;
  background: var(--button-background);
  padding: 8px 12px;
  margin: 0.25rem 0.5rem;

  span {
    position: relative;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    content: "";
    transition: all 0.5s ease-in-out;
    transform: translateX(-80%) translateY(-20px);
    border-radius: 50%;
    background: var(--circle-background);
  }

  &:hover:before {
    width: 400px;
    height: 400px;
    -webkit-transform: translateX(-1%) translateY(-175px);
    transform: translateX(-1%) translateY(-175px);
  }
`;

// 3Dボタン
export const Button3DWrap = styled.div`
  display: inline-block;
  perspective: 800px;
`;

export const Button3D = styled.button`
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
  display: block;
  transform: rotateX(35deg);
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  background: var(--button-background);
  transform-style: preserve-3d;
  padding: 8px 12px;
  margin: 0.25rem 0.5rem;

  &:after {
    position: absolute;
    z-index: -1;
    content: "";
    transition: all 0.4s;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    height: 40%;
    transform: rotateX(-90deg);
    transform-origin: 0 0;
    border-radius: 0 0 4px 4px;
    background: var(--circle-background);
  }

  &:hover {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    color: #fff;
    border-radius: 0.25rem;
  }
`;

// 角丸ボタン
export const ButtonRoundedCorners = styled.button`
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #fff;
  border-radius: 0.5rem;
  padding: 8px 12px;
  margin: 0.25rem 0.5rem;
  background-color: var(--button-background);
  border-bottom: 5px solid var(--circle-background);
  border-radius: 12px;

  &:hover {
    margin-top: 3px;
    border-bottom: 2px solid var(--circle-background);
  }
`;
