type CtaButtonProps = {
  onClick: () => void;
  text: string;
  color1?: string;
  color2?: string;
};
export default function CtaButton({
  onClick,
  text,
  color1 = "#ce4128",
  color2 = "#FF3258",
}: CtaButtonProps): JSX.Element {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`relative rounded px-5 py-2.5 overflow-hidden group bg-[${color1}] hover:bg-gradient-to-r hover:from-[${color1}] hover:to-[${color2}] text-white hover:ring-2 hover:ring-offset-2 hover:ring-[${color2}] transition-all ease-out duration-300`}
      style={
        {
          "--tw-ring-color": color1,
        } as React.CSSProperties
      }
    >
      <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease" />
      <span className="relative">{text}</span>
    </button>
  );
}
